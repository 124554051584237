import Swal from 'sweetalert2';
import store from '@/store'
import api from '../../api.js'
export default{
    
    data() {
        return {    
            api : api
        }
    },
    mounted() {
    },
    methods: {
        // Modal Confirmation delete
        async confirmDelete(title, message, btnConfirm) {

            if(store.state.lacalLanguage==="en"){ 
                return  await Swal.fire({
                        title: title ?? 'Are you sure?',
                        text: message ?? "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: btnConfirm ?? 'OK !'
                    })
                }else{

                    return  await Swal.fire({
                        title: title ?? 'Vous êtez sûr?',
                        text: message ?? "Vous sera pas capable de le recuperer!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: btnConfirm ?? 'OK !'
                    })


                }
        },
        async confirmres(title, message, btnConfirm) {

            if(store.state.lacalLanguage==="en"){ 
                return  await Swal.fire({
                        title: title ?? 'Are you sure to approve this?',
                        text: message ?? "You won't be able edit it",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: btnConfirm ?? 'OK !'
                    })
                }else{

                    return  await Swal.fire({
                        title: title ?? 'Vous êtez sûr de confirmer ces info?',
                        text: message ?? "Vous sera pas capable revenir en arriere!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: btnConfirm ?? 'OK !'
                    })


                }
        },
         
        showDeleteConfirmation() {
        if(store.state.lacalLanguage==="en"){ 
            Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )
            }else{

                Swal.fire(
                    'Supprimé!',
                    'Votre fichier est supprimé avec succèss',
                    'success'
                )

            }
        },
        errorAlert(message) {

        if(store.state.lacalLanguage==="en"){
            Swal.fire(
                        'Error',
                        message ?? "Error !!!",
                        'error'
                    )
                }else{
                    
                    Swal.fire(
                        'oups',
                        message ?? "something worng please try again !!!",
                        'error'
                    )

                }
        },
        successAllert(message = 'Operation success !!' ) {
            if(store.state.lacalLanguage==="en"){
                Swal.fire(
                            'Success',
                            message ,
                            'success'
                        )
            }else{

                Swal.fire(
                    'Réussi',
                    message,
                    'success'
                )
            }
        },
        infoAllert(message = 'Operation success avec informations !!' ) {
            if(store.state.lacalLanguage==="en"){
                Swal.fire(
                            'Success',
                            message ,
                            'info'
                        )
            }else{

                Swal.fire(
                    'Réussi',
                    message,
                    'info'
                )
            }
        },
        
        getroles()
        {
            let userlogged=this.$store.getters.user
            let roles=[]   
				console.log(userlogged.token)
			    userlogged.roles.forEach(element => {
					roles.push({id:element.id,name:element.name})
				});
				console.log(this.roles)
			
            return roles;

        },
        getuserlogged()
        {
            let user=this.$store.getters.user;
           
            return   user.user;
        }
       

    },
    
}