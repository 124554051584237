<template>
  <main>
    <div class="w-full">
      <div ref="form-content" class="p-5 mt-20">
        <button @click="toggleModal" class="flex rounded-sm w-auto p-2 bg-gray-200 hover:bg-gray-600">
          <span class="material-icons">keyboard_double_arrow_left</span>
        </button>
        <Loading v-show="loading" class="h-full" />
        <h1 class=" text-3xl flex justify-center mb-3 ">{{ $t('outputArticleMsg') }}</h1>

        <form action="" @submit.prevent="saveInformation" ref="locationForm">
          <div class="grid grid-cols-3 max-sm:grid-cols-1">
            <div class=" p-3">
              <label for="" class="text-gray-500">{{ $t('receiverNameMsg') }}</label>
              <select name="" id="" required v-model="form.client_id" class="select-buja-wide">
                <option v-for="client in clients" :key="client.id" :value="client.id" class="">
                  {{ client.name }}
                </option>
              </select>
            </div>
            <div class=" p-3">
              <label for="">{{ $t('Motif') }}</label>
              <select name="" id="" required v-model="form.conditions" class="select-buja-wide">
                <option value="location">
                  Location
                </option>
                <option value="event">
                  Events
                </option>
              </select>
            </div>
            <div class=" p-3" v-if="form.conditions == 'event'">
              <label for="" class="text-gray-500">{{ $t('EventMsg') }} </label>
              <select name="" id="" required v-model="form.events_id" class="select-buja-wide">
                <option v-for="event in events" :key="event.id" :value="event.id" class="">
                  {{ event.name }}
                </option>
              </select>
            </div>
            <div class=" p-3">
              <label for="" class="text-gray-500">{{ $t('delivaryDateMsg') }} :</label>
              <input type="date" required placeholder="Amount" v-model="form.nb_days" class="input-buja-wide">
              <div>{{ errors?.amount }}</div>
            </div>
            <div class="p-3">
              <div class="relative z-0">
                <input type="text" required id="default_standard" v-model="form.detail" class="input-buja-wide peer"
                  placeholder=" " />
                <label for="default_standard" class="float-label-buja">{{ $t('DetailMsg') }} </label>
              </div>
              <div>{{ errors?.detail }}</div>
            </div>
            <div class="pb-3 pr-3 pl-3">
              <label for="" class="text-gray-500">{{ $t('Categories') }}</label>
              <select name="" id="" @keypress.enter="change" v-model="category" class="select-buja-wide">
                <option class="hover:bg-green-300" v-for="category in categories" :key="category.id" :value="category"> {{
                  category.name }} </option>
              </select>
            </div>
          </div>
          <div class="pt-5">
            <div class="flex justify-between">
              <h5 class="text-xl font-bold mt-2">{{ $t('EquipmentMsg') }}</h5>
              <span @click="addNewDetail"
                class="cursor-pointer border-2 flex font-[5px] rounded-[3px] w-auto bg-black text-white mr-10 text-center p-1">
                <span class="material-icons">add</span>
                {{ $t('AddMsg') }}
              </span>
            </div>

            <div class="grid grid-cols-2 max-sm:grid-cols-1 gap-10">
              <div class="p-3">
                <label for="" class="block text-gray-500">{{ $t('designationMsg') }}</label>
                <select name="" id="" v-model="form.article_id" class="select-buja-wide">
                  <option v-for="article in filtereditem" :key="article" :value="article" class="hover:bg-green-300">
                    {{ article.name }}
                  </option>
                </select>
              </div>
              <div class=" p-3">
                <label for="" class="text-gray-500">Available:</label>
                <input type="number" disabled p v-model="available" class="input-buja-wide">
                <div>{{ errors?.amount }}</div>
              </div>
              <div class=" p-3">
                <label for="" class="text-gray-500">{{ $t('QuantityMsg') }} :</label>
                <input type="number" p v-model="form.amount" class="input-buja-wide">
                <div>{{ errors?.amount }}</div>
              </div>
            </div>
            <div class="grid grid-cols-4 max-sm:grid-cols-1">
              <div class="p-3 shadow-md" v-for="item in autres" :key="item.id">
                <div class="flex justify-between align-baseline">
                  <div class=" capitalize">
                    <h6>{{ $t('nameMsg') }} :</h6>
                    <h6>{{ $t('QuantityMsg') }} :</h6>
                  </div>
                  <div class="">
                    <h6>{{ item.name }}</h6>
                    <h6>{{ item.quantity }}</h6>
                  </div>
                  <button @click="deleteDetail(item.id)"
                    class="cursor-pointer border-2   font-[5px] rounded-[3px] w-auto bg-dark text-center p-1">X</button>
                </div>
              </div>
            </div>
          </div>
          <div class="flex gap-8 mt-8">
            <button type="submit" class="btn-green-add">{{ saveEditBtn }}</button>
            <button type="reset" class=" btn-red-empty">{{ $t('btnEmpty') }}</button>
          </div>
        </form>


      </div>
    </div>
  </main>
</template>

<script>
import api from '../../api.js'
import result from '@/mixins/result'
import { mapMutations, mapState } from 'vuex';
export default {
  mixins: [result],

  data() {
    return {
      form: {
        article_id: '',
        id: this.$store.state.locationId,
        client_id:'',
        events_id:'',
        nb_days:'',
        detail:'',
        category:'',
        articles: [],
        op: 'new',
        conditions: '',
      },
      articles: [],
      articlesMod: [],
      user: "",
      clients: [],
      events: [],
      roles: [],
      autres: [],
      saveEditBtn: this.$store.state.lacalLanguage === "en" ? "Save" : "Enregistrer",
      category: '',
      categories: [],
      motif: '',
      locations: [],
      user: '',
      available: ""
    }
  },
  mounted() {
    this.getarticles()
    this.getCategory()
    this.getclient()
    this.getevents()
    this.getocations()

    // this.filterevents(this.events,this.locations)
    this.articlesMod = this.$store.state.locations_art
    if (this.$store.state.locationId == null) {
      this.saveEditBtn = this.$store.state.lacalLanguage === "en" ? "Save" : "Enregistrer"
    } else {
      this.saveEditBtn = this.$store.state.lacalLanguage === "en" ? "Edit" : "Modifier"
    }
  },
  components: {
    'Loading': require('@/components/Global/Loading.vue').default
  },

  watch: {
    "form.motif"(val) {
      if (this.form.motif == 'location') {
        this.motif = 'loc'
      }
      else {
        this.motif = 'event'
      }
    },
    "form.article_id"(val) {
      this.available = this.form.article_id.quantity

    }

  },
  computed: {
    filtereditem() {
      return this.change(this.articles, this.category.id)
    },
    filterevent() {
      return this.filterevents(this.events, this.locations)
    }
  },

  methods: {
    change(articles, id) {
      if (Array.isArray(articles)) {
        return articles.filter((item) => item.category_id == id
        )
      }
      console.log(this.articles)
      return articles;

    },

    filterevents(events, locations) {
      let exist_event = []
      if (locations.length > 0) {
        exist_event = events.filter((elem) => {
          return locations.find((ele) => {
            return ele.events_id !== elem.id
          });
        }

        )
      }
      else {
        exist_event = events
      }
      console.log(exist_event)
      //console.log((locations).length)

      return exist_event;

    },

    getevents() {
      api.get("/event")
        .then(resp => {
          this.events = resp.data
          //console.log(this.events)
        })
        .catch(err => {
          console.error(err)
        })

    },
    getclient() {
      api.get("/client")
        .then(resp => {
          this.clients = resp.data
          console.log(this.clients)
        })
        .catch(err => {
          console.error(err)
        })
    },
    getocations() {
      api.get("/location")
        .then(resp => {
          this.locations = resp.data
          console.log(this.clients)
        })
        .catch(err => {
          console.error(err)
        })
    },

    getCategory() {

      api.get("/category")
        .then(resp => {
          this.categories = resp.data
          console.log(this.categories)
          let i = this.getuserlogged()
          console.log(i.id)
        })
        .catch(err => {
          console.error(err)
        })
    },
    ...mapMutations(['WRAP_MODAl', 'TOGGLE_MODAL']),

    toggleModal() {
      // this.WRAP_MODAl(); 
      this.$router.push('/location')
      this.$emit('close')
    },
    SaveData() {
      this.loading = true;
    },
    getarticles() {
      api.get("/article")
        .then(resp => {
          this.articles = resp.data
          console.log(this.articles)
        })
        .catch(err => {
          console.error(err)
        })
    },
    addNewDetail() {
      let result1 = this.articlesMod.filter((item) => item.id == this.form.article_id.id);
      let result = this.autres.filter((item) => item.id == this.form.article_id.id);
      let result2 = this.articles.find((item) => item.id == this.form.article_id.id)

      console.log(result)
      //console.log(result2)

      if (result1.length == 0 && result.length == 0) {
        console.log(this.form.amount)
        if (this.form.amount > result2.quantity) {
          alert('quantité pas disponible')
        }
        else {
          this.autres.push({
            id: this.form.article_id.id,
            name: this.form.article_id.name,
            quantity: this.form.amount,
            addedby: { id: this.getuserlogged().id, name: this.getuserlogged().name }
          });
          this.form.article_id = ""
          this.form.amount = ""
          this.available = ""


        }
        console.log(this.autres)
      }
      else {
        alert('Equipment already added')
      }

    },
    deleteDetail(id) {
      this.autres = this.autres.filter((item) => item.id !== id);
    },
    saveInformation() {
      this.loading = true;
      if (this.form == {}) return; 
      this.form.articles = this.autres
      if (this.locationId == null) {
        api.post(
          "/location",
          this.form
        )
          .then((resp) => {
            this.loading = false;
            this.locations = resp.data;
            this.successAllert()
            this.autres = []
            this.form.articles=[]
            this.form.client_id="",
            this.form.events_id="",
            this.form.nb_days="",
            this.form.detail="",
            this.form.category="",
            this.form.conditions=""
          })
          .catch((err) => {
            this.loading = false;
            this.errorAlert()
            console.error(err.response.data.errors);
            this.errors = err.response.data.errors;
            
          });
      } else {
        api.patch(
          "/location/" + this.locationId,
          this.form)
          .then((resp) => {
            this.locations = resp.data;
            this.loading = false;
            this.successAllert()
            this.$store.state.wrapModal = null;
          })
          .catch((err) => {
            this.errorAlert()
            console.error(err.response.data.errors);
            this.errors = err.response.data.errors;
            
          });

      }

    },



  }

}
</script>

<style></style>