<template>
    <Transition name="wrapModal">
            <wrapModal v-if="wrapModal">
                <formData @dashboardClose="this.$router.go(0)"/>
            </wrapModal> 
        </Transition>
    <div class="w-full p-5 bg-gray-200">

        <div class="flex gap-3 mt-20">
            <h1 class="text-2xl font-bold">Dashboard</h1>
            <input type="date" name="" id="" v-model="datepicker" class="w-40">
        </div>
     <!--   <div class="grid grid-cols-3 mt-5 max-sm:grid-cols-1">

            <div class="flex justify-center mb-3">
                <div class="flex justify-between items-center rounded-lg bg-white p-6 shadow-lg  w-[350px]">
                    <div class="">
                        <h5 class="mb-2 text-xl font-bold leading-tight text-neutral-800 ">
                        Events
                        </h5>
                        <p class="mb-4 text-base text-neutral-600  font-bold">
                        {{ nombre_events }}
                        </p>
                    </div>
                    <div class=" self-center">
                        <span class="material-icons text-6xl">festival</span>
                    </div>
                </div>
            </div>
            <div class="flex justify-center mb-3">
                <div class="flex justify-between items-center rounded-lg bg-white p-6 shadow-lg  w-[350px]">
                    <div class="">
                        <h5 class="mb-2 text-xl font-bold leading-tight text-neutral-800 ">
                        Stock
                        </h5>
                        <p class="mb-4 text-base text-neutral-600  font-bold">
                            {{ stocks }}
                        </p>
                    </div>
                    <div class=" self-center">
                        <span class="material-icons text-6xl">house</span>
                    </div>
                </div>
            </div>

            <div class="flex justify-center mb-3">
                <div class="flex justify-between items-center rounded-lg bg-white p-6 shadow-lg  w-[350px]">
                    <div class="">
                        <h5 class="mb-2 text-xl font-bold leading-tight text-neutral-800 ">
                        Articles
                        </h5>
                        <p class="mb-4 text-base text-neutral-600  font-bold">
                        7000Fbu
                        </p>
                    </div>
                    <div class=" self-center">
                        <span class="material-icons text-6xl">article</span>
                    </div>
                </div>
            </div>
        </div>-->
          <!-- calendar -->

        <div class="mt-3 bg-white p-3">
            <h1 class="flex justify-center text-xl font-bold">
                <loading v-if="status" />
                <span class="ml-3">Event calendar</span>
                <span class=" bg-white rounded-sm w-auto ml-2 cursor-pointer" @click="toggleModal(),this.$store.state.eventId=null">
                 <span class="material-icons">add</span>
                </span>   
            </h1> 
                 
            <FullCalendar  :options="calendarOptions"/>
            
        </div>
         
    </div>
</template>

<script>
import api from '../../api'
import { mapMutations,mapState} from 'vuex';
import result from '../mixins/result';
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
    export default {
        data(){
            return{
                dashboard:[ ],
                mixins:[result],
                nombre_events:'',
                stocks:'',
                status:'',
                events:[],
                datepicker:new Date().toISOString().slice(0,10),

                calendarOptions: {
                    locale:localStorage.getItem('LocalLanguage'),
                    selectable:true,
                    header:{left:'title',center:'dayGridMonth,timegrideWeek,timeGridDay,listWeek',right:'prev today next'},
                    plugins: [ dayGridPlugin, interactionPlugin,timeGridPlugin,listPlugin ],
                    initialView: 'dayGridMonth',
                    dateClick: this.handleDateClick,
                    eventClick: this.handleEventClick,
                    events: [
                    { title: '', date: '' }
                    ]
                }
            }
        },
        components: {
            FullCalendar ,
            'formData':require('@/components/Event/Event/eventForm.vue').default,
            'wrapModal':require('@/components/Global/wrapModal.vue').default,
            'loading':require('@/components/Global/buttonLoading.vue').default
        },
       mounted()
        {
            // this.fetchData()
            this.fetchEvents()
        },
        computed:{
            ...mapState(['wrapModal','eventId','event']),
        },
        methods:{  
            ...mapMutations(['WRAP_MODAl']),

            toggleModal(){
                this.WRAP_MODAl();
                this.$store.state.eventId=null
            },    

            fetchEvents() {
             this.status=true   
            api.get("/event")
            .then(resp => {
                this.events = resp.data
                this.events.forEach(ev =>{
                    const x = new Date(ev.date);
                    x.setDate(x.getDate() + 1);
                    this.calendarOptions.events.push({title:ev.name,date:new Date(ev.date).toISOString().split("T")[0]})
                })
                this.status=false
            })
            .catch(err => {
                console.error(err)
            })
        },
            fetchData() {
                api.get("dashboards")
                .then(resp => {
                    this.dashboard = resp.data
                    this.nombre_events=this.dashboard.nombre_events
                    this.stocks=this.dashboard.stocks              
                })
                .catch(err => {
                    console.error(err)
                })
            },
              handleDateClick: function(arg) {
                alert('date click! ' + arg.dateStr)
                },
                handleEventClick: function(arg) {
                alert("Event: "+arg.event.title+ "  Date: " +arg.event.startStr)
                console.log(arg);
                }
        
        }
        
    };
//    function formatDate(date){
//             var options={year:'numeric',month:'2-digit',day:'2-digit'}
//             let string=new Date(date).toLocaleDateString("ko-KR",options).replace(/\ ./g,"-")
//             return string.substr(0,string.lenght-1)
//             }
</script>

<style lang="scss" scoped>
.material-icons {
font-variation-settings:
'FILL' 0,
'wght' 100,
'GRAD' -25,
'opsz' 40
}

// animated modal
.wrapModal-enter-active,
.wrapModal-leave-active {
    transition: 0.8s ease all;
    position: relative;
    z-index: 99;
}

.wrapModal-enter-from,
.wrapModal-leave-to {
    position: relative;
    z-index: 99; 
    transform: translateX(700px); 
}


</style>