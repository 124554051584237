<template>
    <Transition name="wrapModal">
        <wrapModal v-if="wrap">
            <formData @close="getsortie(id), wrap = false,getlocation()" />
        </wrapModal>
    </Transition>
    <div style="margin: 120px 30px ; width: 80%" v-if="status">
        <button @click="click" class="flex rounded-sm w-auto p-2 bg-gray-200 hover:bg-gray-600">
            <span class="material-icons">keyboard_double_arrow_left</span>
        </button>
        <div class="block  justify-between  rounded-lg bg-gray p-6 shadow-lg dark:bg-neutral-700 max-w-lg mt-6">
            <div class="info">
                <p class="px-2 py-2  font-medium text-gray-900">Client:<span class="px-2">{{ client.name }}</span></p>
                <p class="px-2 py-2 font-medium text-gray-900">Motif:<span class="px-2" v-if="event != null">Event:{{
                    event.name }}</span><span v-else>{{ location.conditions }}</span></p>
            </div>
            <div class="info">
                <p class="px-2 py-2 font-medium text-gray-900">Details:<span class="px-2 leading-tight text-black-900">{{
                    location.detail }}</span></p>
                <p class="py-2 px-2">Deliverd Date: <span class="px-2">{{ date }}</span> </p>
            </div>
        </div>
        <div class="block rounded-lg bg-white p-6 shadow-lg dark:bg-neutral-700 mt-6">
            <button v-show="location.status !== 'done' &&  location.status !== 'finished' || edit==true" v-if="cheftechrole || technicien || this.AdminRole"
                class="rounded-sm p-3 m-3 bg-green-600 hover:bg-green-400" @click="toggleModal(id)">Ajouter
                Articles
            </button>
            <button v-show="location.status == 'done' && location.status !== 'finished' " v-if=" cheftechrole || technicien || this.AdminRole"
                class="rounded-sm p-3 m-3 bg-green-600 hover:bg-green-400" @click="editLoc()">
                Modifier
            </button>
            <button v-show="location.status == 'pending' " v-if="cheftechrole || this.AdminRole"
                class="rounded-sm p-3 m-3 bg-green-600 hover:bg-green-400" @click="confirm()">Confirm Reservation</button>
            <button v-show="location.status !== 'done' || newarticles.length !== 0"
                v-if="stockrole || this.AdminRole" class="rounded-sm p-3 m-3 bg-green-600 hover:bg-green-400"
                @click="sortie()">Autorisé sortie</button>
            <button @click="save" v-show="modif == true"
                class="rounded-sm p-3 bg-primary-600 hover:bg-green-400">save:</button>
            <div class="relative overflow-x-auto  border sm:rounded-lg">
                <table class="w-full text-sm text-left  text-gray-500 dark:text-gray-400" id="dataTable">
                    <thead class="bg-black border sm:rounded-lg">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                Designation
                            </th>
                            <th scope="col" class="px-6 py-3">
                                quantite
                            </th>
                            <th scope="col" class="px-6 py-3">
                                AddedBy:
                            </th>

                            <th scope="col" class="px-6 py-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="bg-white border-b dark:bg-gray-900 dark:border-gray-700" v-for="(item, index) in articles"
                            :key="item.id">
                            <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                               {{ item.name }}
                            </th>
                            <td class="px-6 py-4">
                                <input class="bg-white" @change="onchange(index)"  :min="min"  type="number" disabled
                                    :value="item.quantity" ref="quantityMod">
                            </td>
                            <td class="px-6 py-4">
                                <input class="bg-white" type="text" disabled
                                    :value="JSON.parse(JSON.stringify(item.addedby)).name" ref="addedMod">
                            </td>
                            <td class="">
                                <a href="#"
                                    v-if="location.status !== 'done'  && location.status !== 'finished' ||  edit==true && (cheftechrole || technicien || this.AdminRole)"
                                    class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                    @click="Modifier(index)">Edit</a>
                                <a href="#" v-if=" (cheftechrole || this.AdminRole)" v-show="item.exist!==true && (location.status !== 'done' || location.status !== 'finished')"
                                    class="font-medium text-danger-600 dark:text-blue-500 hover:underline mx-4"
                                    @click="remove(item)">remove</a>

                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div v-else>
        <tableHolder class="w-full" />
    </div>
</template>

<script>


import api from '../../../api.js'
import result from '@/mixins/result';
import { mapMutations, mapState } from 'vuex';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
export default {
    mixins: [result],
    data() {
        return {
            location: [],
            articles: [],
            client: "",
            date: "",
            event: "",
            modif: false,
            form: {
                articles: [],
                op: 'mod',
                new:null,
            },
            status: null,
            wrap: null,
            roles: [],
            edit:false,
            cheftechrole: "",
            technicien: "",
            stockrole: "",
            newarticles: [],
            Getlocation: [],
            Modified:[],
            min:1



        }
    },
   
    props: ['id'],
    components: {
        'formData': require('./addnewForm.vue').default,
        'wrapModal': require('@/components/Global/wrapModal.vue').default,
        'tableHolder': require('@/components/Global/tableSkeleton.vue').default,
      
         'Loading': require('../Global/Loading.vue').default
  
    },

    computed: {
        ...mapState(['wrapModal', 'locationId', 'location']),
        // locations(){
        //         return  this.$store.state.location
        //     }
    },
    mounted() {
        this.getsortie(this.id);
        this.getrole()
        this.getlocation()

    },
    watch:{
        articles(val) {
        
          $('#dataTable').DataTable().destroy();
          this.$nextTick(()=> {
            $('#dataTable').DataTable()
          });
        },
},

    methods: {
        ...mapMutations(['WRAP_MODAl', 'SIDE_BAR']),
        toggleModal(id) {

            this.WRAP_MODAl();
            this.wrap = true
            this.$store.state.locationId = id;
            this.$store.state.locations_art = this.articles

        },
        getrole() {
            this.roles = this.getroles()
            console.log(this.roles)
            this.cheftechrole = this.roles.find((item) => item.id == 5)
            this.AdminRole = this.roles.find((item) => item.id == 1)
            this.technicien = this.roles.find((item) => item.id == 4)
            this.stockrole = this.roles.find((item) => item.id == 6)
        },
        getlocation() {
            let data = { id: this.id }
            api.get("/getlocation", { params: { id: this.id } })
                .then(resp => {
                   
                    this.Getlocation = resp.data
                    let result =this.checkexist(this.articles,this.Getlocation);
                                    
                this.newarticles = this.articles.filter(item => !result.some(item1 => item1.id == item.id)
                )
                console.log(result)
                console.log(this.newarticles)
                console.log(this.articles)
                })
                .catch(err => {
                    console.error(err)
                })
                this.articles.forEach((item)=>{item.exist=''})
        },
        checkexist(articles,location)
        {
            //let location = this.Getlocation
                let result = []
                articles.forEach(element => {
                    //let exist= _.findIndex(location, {'article_id':element.id }) ==-1 ? false : true;
                    location.forEach(element1 => {
                        if (element1.article_id == element.id) {
                            element.exist=true
                            result.push(element)
                        }

                    });

                });
                return result;
            
        },
        getsortie(id) {
            

            api.get("/location/" + id)
                .then(resp => {
                    this.location = resp.data[0]
                    if (resp.status === 200 || resp.status === 204) {
                        this.status = true
                    }
                    else {
                        this.status = false
                    }

                    let client = JSON.stringify(resp.data[0].client)
                    let events = JSON.stringify(resp.data[0].events)
                    this.event = JSON.parse(events)
                    this.client = JSON.parse(client)
                    this.date = resp.data[0].nb_days.substr(0, 10)
                    this.articles = JSON.parse(resp.data[0].articles)
                    this.articles.forEach((item)=>{item.Mod=false})
                    console.log(this.articles)

                })

                .catch(err => {
                    console.error(err)
                })
        },
        click() {
            this.$router.push('/Location')
            this.$store.state.wrapModal = null
        },
        Modifier(index) {
            this.modif = true
            this.articles.forEach((item)=>{item.quantityMod=0})
            if(this.articles[index].exist==true)
            {
                let numbertoadd = window.prompt("Entrer le nombre a ajouter")
                this.$refs.quantityMod[index].value= (this.$refs.quantityMod[index].value)*1 + (numbertoadd*1);
                this.articles[index].quantityMod =(numbertoadd*1) ;
                this.articles[index].quantity = this.$refs.quantityMod[index].value
                console.log(this.$refs.quantityMod[index].value)
                console.log(this.articles)
            }
            else{
                
            let a = this.$refs.quantityMod[index]
            console.log(a)
            a.disabled = false
            a.class = "bg-gray"

            }
            
        },
        editLoc()
        {
            this.edit=true

        },
        remove(index) {
            this.status = false
            console.log(index)
            //this.articles = this.articles.filter(function (el) { return el.id !== index.id; });
            console.log(this.articles)
            console.log(index)
            this.form.articles = this.articles

            const v={
                data:index,
                op:'del',
                articles:this.form.articles
            }
            api.patch(
                "/location/" + this.id,
                v)
                .then((resp) => {
                    this.status = true
                   
                    //this.locations = resp.data;
                    
                    this.successAllert()
                    this.getsortie(this.id)
                    this.getrole()
                    this.getlocation()
                    this.status = false;
                   
                    //  this.$store.state.wrapModal = null;
                })
                .catch((err) => {
                    this.status = true
                    this.getrole()
                    console.log(err)
                    this.errorAlert()
                });

        },
        checkArticles(articles, getarticles) {

            this.newarticles = articles.filter(item => getarticles.some(itemToBeRemoved => itemToBeRemoved.article_id !== item.id))
            console.log(this.newarticles)

        },
        save() {
            this.form.articles = this.articles
            this.form.op="mod"
            this.status = false

            api.patch(
                "/location/" + this.id,
                this.form)
                .then((resp) => {
                    //this.locations = resp.data;
                    
                    this.successAllert(resp.data.message)
                    this.modif = false;
                    let a = this.$refs.quantityMod
                    this.status = true;
                    
                    a.forEach(element => {
                        element.disabled = true
                        console.log(element.value)
                    });
                    this.getsortie(this.id)
                    this.newarticles = []
                   this.getrole()
                   this.getlocation()
                   this.status = true;

                    //  this.$store.state.wrapModal = null;
                })
                .catch((err) => {
                    let errorda = (JSON.stringify(err?.data?.data))
                    let erromessage = JSON.stringify(err?.data.message)
                    alert(errorda + '' + erromessage)
                    //this.errorAlert()
                    console.error(err.response.data.errors);
                    this.errors = err.response.data.errors;
                });
        },
        sortie() {
            if(this.location.status=='pending')
            {
                alert('you have to approuve first')
            }
            
            else{
                this.status = false
                this.form.op = 'done'
            let articles = this.articles
                this.form.new = this.newarticles
                articles.forEach(element => {
                    element.addedby = element.addedby.id
                    console.log(element)
                });
                
                this.form.articles = articles
                console.log(this.newarticles)
                if(!this.newarticles)
                {
                    this.form.new=null
                }
                else{
                    this.form.new=this.newarticles

                }
                console.log(this.form)    
                this.confirmres().then((result) => {
                if (result.isConfirmed) {
                    api.patch("/location/" + this.id,this.form)
                .then(resp => {

                    this.successAllert()
                    //this.categories = resp.data.data 
                    this.getsortie(this.id)
                    this.newarticles = []
                   this.getrole()
                   this.getlocation()
                   this.status = true
                })
                .catch(err => {
                    this.errorAlert()
                    this.status = true
                    
                })
                }
            })

            }
            
            
            
        },
        confirm() {
            this.status = false
            this.form.op = 'approve'
            this.form.articles = this.articles
            this.confirmres().then((result) => {
                if (result.isConfirmed) {
                    api.patch("/location/" + this.id, this.form)
                        .then(resp => {

                            this.successAllert()
                            
                            //this.categories = resp.data.data 
                            this.getrole()
                            this.getsortie(this.id)
                            this.status = true
                        })
                        .catch(err => {
                            this.getrole()
                            console.error(err)
                            this.errorAlert()
                            this.status = true
                        })
                }
            })
        },
        onchange(index) {
            this.articles[index].quantity = this.$refs.quantityMod[index].value 
            this.articles[index].Mod= true    
             console.log(this.articles)
           
        },
        
    }
}

</script>

<style></style>