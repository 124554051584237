<template class="temp">
    <div class="mainc p-5 w-full ">
    <button onclick="window.print()" v-if="!invonce.is_cancelled_at" class="no_print flex bg-buja-primary p-2 float-right  mt-20">
        <span class="material-icons" >print</span>
        <span class="font-bold">Imprimer</span>
    </button>
       <div style="margin: 0 50px ;" class="clear-both">
                <div style=" padding: 20px; width: 80%;">
                    <img :src="bujaEvent" alt="" style="width: 100px;" >
                    <span style="color: gray;">L'innovateur  de l'évènementiel au Burundi</span>
                    <div style="border-bottom: 4px solid gray; margin-left: 70px; margin-bottom: 5px;"></div>
                </div>

                <h1 class="facture-header" style="text-align:center; font-weight: bold;">FACTURE N° {{ invonce.id }} du  {{new Date(invonce.date_facture).toLocaleDateString("en-US")}}</h1>
              <div class="flex justify-between mb-[10px] w-full max-lg:block">
                    <div class="nif-info">
                        <h1 style="margin-bottom: 5px;">A. Identification du Vendeur</h1>
                        <div>Société: {{ company.tp_name }}</div>
                        <div>NIF: <span>{{ company.tp_TIN }}</span></div>
                        <div>RC N॰: <span>{{ company.tp_trade_number }}</span></div>
                        <div>Centre fiscal: <span>{{ company.tp_fiscal_center }}</span></div>
                        <div>Secteur d'activités: <span>{{ company.tp_activity_sector }}</span></div>
                        <div>
                            <span>
                            Assujetti à la TVA: 
                            <span v-if="company?.vat_taxpayer==1">OUI</span>
                            <span v-else>NON</span>       
                           </span>
                        </div>
                        <div>
                            <span>B.P: {{ company.tp_postal_number}}</span> 
                            <span style="margin-left: 5px;">Tél:{{ company.tp_phone_number }}</span>
                        </div>
                        <div>
                            <span>Commune: {{ company.tp_address_commune }}</span>,
                            <span>Quartier/Colline : {{ company.tp_address_quartier }} </span>
                        </div>
                        <div> 
                            <span>Av. {{ company.tp_address_rue}}</span>,
                             <span>N॰: {{ company.tp_address_number}} </span>
                        </div>
                    </div>
                    <div class="client-info">
                        <h1 style="margin-bottom: 5px;">B. Identification du Client</h1>
                        <div>Status: {{ invonce?.client?.company_nature }}</div>
                        <div v-if="invonce?.client?.company_nature=='pers_phys'"> 
                            <span style="display: block; ">Prénom: {{ invonce?.client?.lastName }}</span>
                            <span style=" display: block;">Raison sociale: {{ invonce?.client?.raison_social }}</span>
                        </div>
                        <div><span style="display: block;">Nom: {{ invonce?.client?.name }}</span></div>
                        <div><span>NIF: {{ invonce?.client?.customer_TIN }}</span></div>
                        <div><span>Addresse: {{ invonce?.client?.addresse }}</span></div>
                        <div>
                            <span>
                            Assujetti à la TVA: 
                            <span v-if="invonce?.client?.vat_customer_payer==1">OUI</span>
                            <span v-else>NON</span>       
                           </span>
                        </div>
                    </div>
              </div>
              <div>
                <table >
                    <thead style="background-color: green;">
                        <tr>
                            <th>DESIGNATION</th>
                            <th> QUANTITE </th>
                            <th>PU</th>
                           <!--- <th>Tva</th>-->
                            <th> PT</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in invonce.details" :key="item">
                            <td>{{ item.item_designation }}</td>
                            <td>{{ item.item_quantity }}</td>
                            <td>{{ item.item_price }}</td>
                          <!--  <td>{{ item.vat }}</td>-->
                            <td>{{ item.item_price_wvat }}</td>
                  
                        </tr>
                    
                       
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="3">TOTAL HTVA</td>
                            <td colspan="1"> {{ invonce.total_price_nvat }}</td>
                        </tr>
                        <tr>
                            <td colspan="3">TVA</td>
                            <td colspan="1"> {{ invonce.total_vat }}</td>
                        </tr>
                        <tr>
                            <td colspan="3">TOTAL TVAC</td>
                            <td colspan="1">{{  Math.round(invonce.total_price_wvat) }}</td>
                        </tr>

                    </tfoot>
                </table>
              </div>
              <div style="margin-top: 30px;">
                Nous disons <span style="font-weight: bold;">

              {{ montant_lettre }}
                </span>  francs burundais   TVAC.
              </div>
              <div style="margin-top: 60px;color: gray; ">
                <div style="border-bottom: 4px solid gray; margin-left: 40px; margin-bottom: 5px;"></div>
                KABONDO 11 Av.HEHA* BUJUMBURA-BURUNDI* CONTACT:75 908 519* NIF:4000902173* Compte: Compte Bancaire : 20002-11101-04474420101-84 ouvert a la Bancobu
              </div>

              <div class="text-center">
              {{ invonce.invoice_identifier }}
              </div>
        </div>
    </div>
</template>

<script>
import api  from "../../api.js"
import bujaEvent from "@/assets/logo.png"
import { DatetimeFormat } from "vue-i18n";
export default {
    data() {
        return {
            bujaEvent: bujaEvent,
            invonce: {},
            company: {},
            montant_lettre: "",
            date:"",
            facture_id : this.$route.query.id
        }
    },
    mounted() {
        this.get();
        if (this.invonce?.client?.vat_customer_payer==1) {
            this.TVA='OUI'
        }else{
            this.TVA='NON '
        }
    },
    methods: {
        get() { 
           
            api.get('get_invoinces/' + this.facture_id)
                .then(result => {
                    this.company = result.data.company
                    this.invonce = result.data.invonce
                    this.montant_lettre = result.data.montant_lettre
                })
                .catch(error => {
                    console.log(error)
                });
        }
    }
}
</script>

<style lang="scss" scoped>

@media print {
    .no_print{
        display: none;
    }
    table{
        width: 70%;   
    }
     
    .clear-both
    {
       margin-top:12px;
       margin-left: 0;
       font-size: 12px;
    }
    .temp{
        margin:0;
        padding:0;
        background-color: aqua;

    } 
   
    .mainc{
        width:100%;
        height:100%;
        margin-left:-25%;
        margin-right: 14%;
        padding:0;
    }
   
    @page {
    margin: 0;
    
}
    
}
   


   table{
    width: 100%; margin-top: 5px;
  
   }


   table, th, td {
      border: 1px solid black;
      border-collapse: collapse;
      padding-left:10px;
    }

    .nif-info{
        font-weight: bold;
        clear: both;
    }

    .client-info{
        font-weight: bold;
       
    }

</style>