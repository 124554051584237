import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
//Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
//import 'vue-toast-notification/dist/theme-bootstrap.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import i18n from '@/plugins/i18n.js'
import FlagIcon from 'vue-flag-icon';
import * as te from 'tw-elements';
import 'flowbite';

createApp(App).use(store).use(router).use(i18n).use(FlagIcon).mount('#app').use(VueSweetalert2);
