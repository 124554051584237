<template>
    <main>
        <Transition name="wrapModal">
            <wrapModal v-if="wrapModal">
                <div :class="`${isLoading ? 'buja-form-content' : ' buja-form-content'}`" ref="form-content" class="FormCl">
                    <Loading v-show="isLoading" class="h-full" />
                    <h1 class=" text-3xl flex justify-center mb-3 ">Annulation du Facture</h1>
                    <form action="" @submit.prevent="sentMotifToObr" ref="serviceForm">
                        <div class="p-3">
                            <label for="default_standard" class="text-lg">Motif </label>
                            <div class="">
                                <textarea required name="" @keyup="EcrisMotif()" id="" v-model="motif" cols="30" rows="5"
                                    class="py-2 px-1 w-full bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"></textarea>
                            </div>
                            <div>{{ errors?.motif }}</div>
                            <small class="text-danger" v-if="isMotif==true">vous devez  saisir un motif d'annulation.</small>
                        </div>
                        <div class="flex gap-8 mt-5">
                            <button type="submit"  class="btn-green-add">Envoyer</button>
                            <button type="reset" class="btn-red-empty">{{ $t('btnEmpty') }}</button>
                        </div>
                    </form>
                    <button @click="toggleModal" class="btn-gray-close">{{ $t('btnClose') }}</button>
                </div>
            </wrapModal>
        </Transition>
        <div class="p-5 mt-[30px]">
            <div class="w-full">
                <!-- <div class="flex mb-2">
                    <select name="" id="" v-model="invonce_type" class="py-2 px-1 border-black w-[200px] border-2 focus:border-buja-primary focus:outline-none mr-3 focus:shadow-[0px_1px_0_0_#004E71]">    
                        <option value=""></option>
                        <option value="0">{{ $t('pendingInvoiceMsg') }}</option>
                        <option value="1"> {{ $t('sentInvoiceMsg') }}</option>
                    </select>                 
                </div>        -->
                <button @click="this.$router.push('/facture')"
                    class="flex rounded-sm w-auto p-2 bg-gray-200 hover:bg-gray-600 mt-4">
                    <span class="material-icons">keyboard_double_arrow_left</span>
                </button>
                <div v-if="status">
                    <!-- <Loading v-show="isLoading" class="h-full" /> -->
                    <div class="mx-auto">
                        <div class="flex flex-col">
                            <div class="w-full">
                                <div
                                    class="p-8 mt-3 relative overflow-x-auto shadow sm:rounded-lg border-b border-gray-200">
                                    <table class=" w-full" id="dataTable">
                                        <thead class="bg-black">
                                            <tr class=" border border-b">
                                                <th class="px-6 py-2 text-xs text-white capitalize">#</th>
                                                <th class="px-6 py-2 text-xs text-white capitalize">{{ $t('clientMsg') }}
                                                </th>
                                                <th class="px-6 py-2 text-xs text-white capitalize">{{ $t('clientMsg') }} N॰
                                                </th>
                                                <th class="px-6 py-2 text-xs text-white capitalize">OBR Response
                                                </th>
                                                <th class="px-6 py-2 text-xs text-white capitalize">{{ $t('RecieptMsg') }}
                                                </th>

                                                <th class="px-6 py-2 text-xs text-white capitalize">HTVA</th>
                                                <th class="px-6 py-2 text-xs text-white capitalize">TOTAL_TVA</th>
                                                <th class="px-6 py-2 text-xs text-white capitalize">TVAC</th>

                                                <!--<th class="px-6 py-2 text-xs text-white capitalize">Status de Facture</th>-->

                                                <th class="px-6 py-2 text-xs text-white capitalize">{{ $t('StateMsg') }}
                                                </th>
                                                <th class="px-6 py-2 text-xs text-white capitalize">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody class="bg-white divide-y divide-gray-300">
                                            <tr v-for="facult in facturesList" :key="facult.id"
                                                class="text-center whitespace-nowrap">
                                                <td class="px-6 py-4 text-sm text-gray-500">
                                                    {{ facult.id }}
                                                </td>
                                                <td class="px-6 py-4">
                                                    <div class="text-sm text-gray-900">
                                                        {{ facult.client?.name }}
                                                    </div>
                                                </td>
                                                <td class="px-6 py-4">
                                                    <div class="text-sm text-gray-900">
                                                        {{ facult.client?.id }}
                                                    </div>
                                                </td>
                                                <td class="px-6 py-4">
                                                    <div class="text-sm text-gray-900">
                                                        {{ facult?.response_obr?.msg }}
                                                    </div>
                                                </td>
                                                <td class="px-6 py-4">
                                                    <div class="text-sm text-gray-900">
                                                        {{ facult?.invoice_identifier?.substring(0, 15) + '...' }}
                                                    </div>
                                                </td>
                                                <td class="px-6 py-4">
                                                    <div class="text-sm text-gray-900">
                                                        {{ formatNumber(facult.total_price_nvat) }}
                                                    </div>
                                                </td>
                                                <td class="px-6 py-4">
                                                    <div class="text-sm text-gray-900">
                                                        {{ formatNumber(facult.total_vat) }}
                                                    </div>
                                                </td>
                                                <td class="px-6 py-4">
                                                    <div class="text-sm text-gray-900">
                                                        {{ formatNumber(facult.total_price_nvat) }}
                                                    </div>

                                                </td>
                                                <!--<td class="px-6 py-4">
                                                                <p>{{ facult.is_cancelled_at }}</p>
                                                                </td>-->

                                                <td class="px-6 py-4">
                                                    <div>
                                                        <span v-if="facult.is_sent_to_obr == 1 && facult.is_cancelled_at==null">Sent</span>
                                                        <span v-else-if="facult.is_sent_to_obr == 0 && facult.is_cancelled_at==null && facult.cn_motif!=null">pending_and_cancelled</span>
                                                        <span v-else-if="facult.is_sent_to_obr == 0">pending</span>
                                                        <span v-else-if="facult.is_sent_to_obr == 1 && facult.is_cancelled_at!==null">cancelled</span>


                                                    </div>
                                                </td>

                                                <td class="px-6 py-4">
                                                    <div class=" flex gap-3 text-sm text-gray-900">
                                                        <!-- <button v-if="!facult.is_sent_to_obr" @click="editF(facult.id,facult)"
                                                                               class="flex border-black border-2 gap-1 rounded-sm p-2 w-auto">
                                                                               <span class="material-icons">edit</span>
                                                                            </button>
                                                                            <button v-if="!facult.is_sent_to_obr" @click="deleteF(facult.id)"
                                                                               class="flex border-black border-2 gap-1 rounded-sm p-2 w-auto">
                                                                               <span class="material-icons">delete</span>
                                                                            </button> -->
                                                        <button v-if="facult.is_sent_to_obr && !facult.is_cancelled_at" @click="detailFacture(facult.id)">
                                                            <span class="material-icons">print</span>
                                                        </button>

                                                        <!-- <button v-if="!facult.is_sent_to_obr" @click="sentToObr(facult.id)">
                                                                             Sent to                  OBR
                                                                                </button> 
                                                                            <button v-if="!facult.is_sent_to_obr" @click="sentToObr(facult.id)"
                                                                               class="flex border-black border-2 gap-1 rounded-sm p-2 w-auto">
                                                                                <span>{{ $t('Send') }}</span>   
                                                                               <span class="material-icons">send</span>
                                                                            </button>-->


                                                        <button v-if="!facult.is_cancelled_at && !facult.cn_motif"
                                                            @click="cancelInvonce(facult.invoice_identifier),this.motif=''">
                                                            <span class="material-icons">cancel</span>
                                                        </button>

                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <tableHolder class="clear-both" />
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import api from "../../api.js";
import { mapMutations, mapState } from 'vuex';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import result from '@/mixins/result';
export default {
    mixins: [result],
    data() {
        return {
            factures: [],
            invonce_type: "",
            isLoading: false,
            motif: "",
            isMotif:false,
            invoice_identifier: "",
            status: null,
        }
    },
    components: {
        'formData': require('@/components/facture/cancel_invoiceForm.vue').default,
        'wrapModal': require('@/components/Global/wrapModal.vue').default,
        'Loading': require('@/components/Global/Loading.vue').default,
        'tableHolder': require('@/components/Global/tableSkeleton.vue').default,

    },
    watch: {
        factures() {
            $('#dataTable').DataTable().destroy();
            this.$nextTick(() => {
                $('#dataTable').DataTable(
                    {
                        "ordering": false
                    }
                )
            });
        },
    },
    mounted() {
        this.get();
    },
    methods: {
        ...mapMutations(['WRAP_MODAl', 'SIDE_BAR']),

        toggleModal() {
            this.WRAP_MODAl();

        },
        cancelInvonce(invoice_identifier) {
            // alert(invoice_identifier)
            this.WRAP_MODAl();
            this.invoice_identifier = invoice_identifier

            // while (motif === "") {
            //     motif = window.prompt("Entrez le Motif d'annulation du Facture");

            //     if (motif === "") {
            //         alert("vous devez  saisir un motif d'annulation.");
            //     }
            // }

            // if (motif !== "") {
            //     this.isLoading = true;
            //     api.post('cancelInvoince', {
            //         signature: invoice_identifier,
            //         cn_motif: motif,
            //     }).then(resp => {
            //         //alert(resp.data.msg)
            //         this.successAllert(resp.data.msg);
            //     }).catch(err => {
            //         this.errorAlert()
            //         console.log(JSON.stringify(err))
            //     })
            // }

        },
        EcrisMotif(){
            if(this.motif.trim()!=""){
                this.isMotif=false
            }
        },
        sentMotifToObr() {
            if(this.motif.trim() ==""){
                this.isMotif=true
            }else{
                this.isLoading = true;
                api.post('cancelInvoince', {
                    signature: this.invoice_identifier,
                    cn_motif: this.motif.trim(),
                }).then(resp => {
                    this.isLoading = false;
                    if(resp.data.success){
                        this.motif=""
                        this.get()
                        this.successAllert(resp.data.msg);
                       
                    }else{
                        this.errorAlert(resp.data.msg)
                    }
                }).catch(err => {
                    this.isLoading = false;
                    this.errorAlert()
                    console.log(JSON.stringify(err))
                })
            }
        },
        sentToObr(invoice_id) {
            this.isLoading = true;
            api.get('send_invoice/' + invoice_id)
                .then(resp => {
                    this.successAllert(resp.data.msg);
                    this.get();
                    this.isLoading = false;
                })
                .catch(err => {
                    this.isLoading = false;
                    this.errorAlert()
                    console.log(JSON.stringify(err))
                })
        },

        synchronize() {
            this.isLoading = true;
            api.get('sent_pending_invoncies')
                .then(result => {
                    console.log(result)
                    this.isLoading = false;
                });
        },
        detailFacture(id) {
            console.log(id)
            this.$router.push({ path: 'detail_facture', query: { id: id } })
        },

        formatNumber(number) {

            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'FBU' }).format(number);
        },
        get() {
            api.get('factures')
                .then(resp => {
                    console.log(resp);
                    this.factures = resp.data
                    console.log("the status is ", resp.status);
                    if (resp.status === 200 || resp.status === 204) {
                        this.status = true
                    }
                    else {
                        this.status = false
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        },
        editF(id, Facture) {
            this.$store.state.Facture = Facture
            this.$store.state.FactureId = id
            this.$router.push('/facture')
            console.log(this.$store.state.Facture)
            console.log(this.$store.state.FactureId)


        },
        deleteF(id) {
            this.$store.state.FactureId = id
            this.confirmDelete().then((result) => {
                if (result.isConfirmed) {
                    api.post("deletInvoice", { id: id })
                        .then(resp => {
                            this.showDeleteConfirmation()
                            //this.categories = resp.data.data 
                            this.get();
                        })
                        .catch(err => {
                            this.errorAlert()
                            console.error(err)

                        })
                }
            }
            )

        }
    },
    computed: {
        ...mapState(['wrapModal', 'FactureId', 'Facture']),
        facturesList() {
            return this.invonce_type ? this.factures.filter(facture => facture.is_sent_to_obr = this.invonce_type) : this.factures
        }
    }
}
</script>

<style lang="scss" scoped></style>