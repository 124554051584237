import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import StockView from '../views/stockView.vue'
import CategoryView from '../views/categoryView.vue'
import Dashboard from '../views/Dashboard.vue'
import ArticleView from '../views/ArticleView.vue'
import ServiceView from '../views/serviceView.vue'
import ClientView from '../views/ClientView.vue'
import LocationView from '../views/LocationView.vue'
import RequisitionView from '../views/RequisitionView.vue'
import settingView from '@/views/settingView.vue'
import eventCategoryView from '@/views/EventCategoryView.vue'
import EventView from '@/views/EventView.vue'
import GetEventdetail from '@/components/Event/Event/GetEvent.vue'
import TickeTypeView from '@/views/TickeTypeView.vue'
import EventPriceView from '@/views/EventPriceView.vue'
import eventOrderView from '@/views/eventOrderView.vue'
import eventDetailView from '@/views/eventDetailView.vue'
import FactureView from '@/views/FactureView.vue'
import SortieView from '@/views/SortieView.vue'
import FactureList from '@/views/FactureList.vue'
import returnArticleForm from '@/components/returnarticles/returnform.vue'
import returnArticle from '@/components/returnarticles/returntables.vue'
import returnView from '@/components/returnarticles/viewreturn.vue'
import fichesortie from '@/components/locations/fichesortie.vue'
import FactureDetail from '@/views/FactureDetail.vue'
import usersView from '@/views/usersView.vue'
import viewreservation from '@/components/locations/viewsortie.vue'
import organizerView from '@/views/organizerView.vue'
import articleForm from '@/views/articleFormView.vue'
import LocationForm from '@/views/LocationFormView.vue'
import Report from '@/views/report.vue'



const routes = [
  {
    path: "/login",
    name: "Login",
    component: ()=>import('@/components/Global/login.vue'),
    meta: {
      title:store.state.lacalLanguage==="en" ? "Aunthentication" : "Authentification "
    },
  }, 
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Dashboard" : "Tableau de Bord"
    },
  }, 
  {
    path: "/report",
    name: "Report",
    component: Report,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Report" : "Rapport"
    },
  }, 
  {
    path: "/stock",
    name: "Stock",
    component: StockView,
    meta: {
      title:"Stock"
    },
  },
  {
    path: "/users",
    name: "users",
    component: usersView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "User" : "Utilisateur",
      requiresAuth: true,
      adminAuth: true,
      managerM: false,
      gerantstock:false,
      technicien:false,
      cheftech:false,
      
    },
  },
  {
    path: "/view/:id",
    name: "view",
    component: viewreservation,
    props: true,
    meta: {
      title:store.state.lacalLanguage==="en" ? "view equipment reservation" : "Equipement Reservé",
      requiresAuth: true,
      adminAuth: true,
      managerM: false,
      gerantstock:true,
      technicien:true,
      cheftech:true,
    },
  },
  {
    path: "/fiche/:id",
    name: "fiche",
    component: fichesortie,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Output file" : "Fiche de sortie",
      requiresAuth: true,
      adminAuth: true,
      managerM: false,
      gerantstock:true,
      technicien:false,
      cheftech:true,
      
    },
    props: true
  },
  {
    path: "/Facture",
    name: "Facture",
    component: FactureView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Invoice" : "Facture",
      requiresAuth: true,
      adminAuth: true,
      managerM: true,
      comptable:true,
      gerantstock:false,
      technicien:false,
      cheftech:false,
      
      
    },
  },
  {
    path: "/detail_facture",
    name: "detail_facture",
    component: FactureDetail,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Invoice Details" : "Détails des Factures",
      requiresAuth: true,
      adminAuth: true,
      comptable:true,
      managerM: true,
      gerantstock:false,
      technicien:false,
      cheftech:false,
    },
  },
  {
    path: "/facture_list",
    name: "FacturList",
    component: FactureList,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Invoice List" : "Listes des Factures",
      requiresAuth: true,
      adminAuth: true,
      comptable:true,
      managerM: true,
      gerantstock:false,
      technicien:false,
      cheftech:false,
    },
  },
  {
    path: "/category",
    name: "Category",
    component: CategoryView,
    meta: {
      title: store.state.lacalLanguage==="en" ? "Category" : "Catégorie",
      requiresAuth: true,
      adminAuth: true,
      managerM: false,
      gerantstock:true,
      technicien:true,
      cheftech:true,
    },
  },
  /*{
    path: "/Sortie",
    name: "Sortie",
    component: SortieView,
    meta: {
      title: store.state.lacalLanguage==="en" ? "Output" : "Sortie",

    },
   
  },*/
  {
    path: "/article",
    name: "Article",
    component: ArticleView,
    meta: {
      title:"Article",
      requiresAuth: true,
      adminAuth: true,
      managerM: false,
      gerantstock:true,
      technicien:true,
      cheftech:true,
    },
  },
  {
    path: "/article_form",
    name: "Article_Form",
    component: articleForm,
    meta: {
      title: store.state.lacalLanguage==="en" ? "Article Form" : "formulaire des Article",
      requiresAuth: true,
      adminAuth: true,
      managerM: false,
      gerantstock:true,
      technicien:true,
      cheftech:true,

    },
  },
  {
    path: "/service",
    name: "Service",
    component: ServiceView,
    meta: {
      title: "Service",
      requiresAuth: true,
      adminAuth: true,
      comptable:true,
      managerM: true,
      gerantstock:false,
      technicien:false,
      cheftech:false,
    },
  },
  {
    path: "/client",
    name: "Client",
    component: ClientView,
    meta: {
      title: store.state.lacalLanguage==="en" ? "Customer" : "Client",
      requiresAuth: true,
      adminAuth: true,
      managerM: true,
      comptable:true,
      gerantstock:false,
      technicien:false,
      cheftech:false,
    },
  },
  {
    path: "/location",
    name: "Location",
    component: LocationView,
    meta: {
      title: store.state.lacalLanguage==="en" ? "Rental" : "Location",
      requiresAuth: true,
      adminAuth: true,
      managerM: false,
      gerantstock:true,
      technicien:true,
      cheftech:true,
    },
  },
  {
    path: "/location_form",
    name: "Location_Form",
    component: LocationForm,
    meta: {
      title: store.state.lacalLanguage==="en" ? "Rental Form" : "formulaire des locations",
      requiresAuth: true,
      adminAuth: true,
      managerM: false,
      gerantstock:false,
      technicien:true,
      cheftech:true,
    },
  },
  {
    path: "/return/:id",
    name: "returnid",
    component: returnArticleForm,
    meta: {
      title:store.state.lacalLanguage==="en" ? "return articles" : "Articles retournés",
      requiresAuth: true,
      adminAuth: true,
      managerM: false,
      gerantstock:true,
      technicien:false,
      cheftech:true,
    },
    props: true
  },
  {
    path: "/viewretun/:id",
    name: "viewretun",
    component: returnView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "return articles" : "Articles retournés",
      requiresAuth: true,
      adminAuth: true,
      managerM: false,
      gerantstock:true,
      technicien:false,
      cheftech:true,
    },
    props: true
  },
  {
    path: "/return",
    name: "return",
    component: returnArticle,
    meta: {
      title:store.state.lacalLanguage==="en" ? "return articles" : "Articles retournés",
      requiresAuth: true,
      adminAuth: true,
      managerM: false,
      gerantstock:true,
      technicien:false,
      cheftech:true,
    },
    
  },
  {
    path: "/requisition",
    name: "Requisition",
    component: RequisitionView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Requisition" : "Réquisition"
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: settingView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Settings" : "Paramétre",
      requiresAuth: true,
      adminAuth: true,
      managerM: true,
      gerantstock:true,
      technicien:true,
      cheftech:true,
      comptable:true,
    },
  },
  {
    path: "/eventCategory",
    name: "Event Category",
    component: eventCategoryView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Event Category" : "Catégorie d'évenément",
      requiresAuth: true,
      adminAuth: true,
      managerM: true,
      gerantstock:false,
      technicien:false,
      cheftech:false,
    },
  },
  {
    path: "/event",
    name: "Event",
    component: EventView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Event" : "évenément",
      requiresAuth: true,
      adminAuth: true,
      managerM: true,
      gerantstock:false,
      technicien:false,
      cheftech:false,
    },
  },
  {
    path: "/ticket_type",
    name: "Ticket type",
    component: TickeTypeView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Ticket type" : "Type de ticket",
      requiresAuth: true,
      adminAuth: true,
      managerM: true,
      gerantstock:false,
      technicien:false,
      cheftech:false,
    },
  },
  {
    path: "/event_price",
    name: "Event Price",
    component: EventPriceView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Event Price" : "Prix d'évenément",
      requiresAuth: true,
      adminAuth: true,
      managerM: true,
      gerantstock:false,
      technicien:false,
      cheftech:false,
    },
  },
  {
    path: "/Event/:id",
    name: "GetEventdetail",
    component: GetEventdetail,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Event detail" : "Detail Evenenement",
      requiresAuth: true,
      adminAuth: true,
      managerM: false,
      gerantstock:true,
      technicien:false,
      cheftech:true,
    },
    props: true
  },
  {
    path: "/event_order",
    name: "Event Order",
    component: eventOrderView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Event Order" : "commande d'évenément",
      requiresAuth: true,
      adminAuth: true,
      managerM: true,
      gerantstock:false,
      technicien:false,
      cheftech:false,
    },
  },
  {
    path: "/event_detail",
    name: "Event Detail",
    component: eventDetailView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Event Detail" : "detaille d'évenément",
      requiresAuth: true,
      adminAuth: true,
      managerM: true,
      gerantstock:false,
      technicien:false,
      cheftech:false,
    },
  },
  {
    path: "/organiser",
    name: "Organizer",
    component: organizerView,
    meta: {
      title:store.state.lacalLanguage==="en" ? "Organizer" : "Organisateur",
      requiresAuth: true,
      adminAuth: true,
      managerM: true,
      gerantstock:false,
      technicien:false,
      cheftech:false,
    },
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title } | Buja Event`;
  next();
  var roles=[]
  let userlogged=JSON.parse(localStorage.getItem('userBujEvent'))
  userlogged.roles.forEach(element => {
  roles.push({id:element.id,name:element.name})
  });
 
let cheftechrole=roles.find((item)=>item.id==5)
  let accesstoken=userlogged.token
  let adminrole=roles.find((item) => item.id == 1)
  let agentrole=roles.find((item)=>item.id==2)
  let simplerole=roles.find((item)=>item.id==3)
let technicien=roles.find((item)=>item.id==4)
let stockrole=roles.find((item)=>item.id==6)
let MaganagerM=roles.find((item)=>item.id==7)
let comptable=roles.find((item)=>item.id=8)



        if (to.meta.requiresAuth) {
          if (!roles || !accesstoken ) {
            router.push({ path: '/login' });
          } else {
            if (adminrole) {
              
                if(to.meta.adminAuth)
                {
                  next();
                } else {
                  router.push({ path: '/' });
                }
   
            } 

           else if(cheftechrole)
            {
               if(to.meta.cheftech)
            {
                  next();
                } else {
                  router.push({ path: '/' });
                }

            }

            
            
            else if( MaganagerM)
            {
              if(to.meta.managerM)
                {
                  next();
                } else {
                  router.push({ path: '/' });
                }

            }
            else if(technicien)
            {
              if(to.meta.technicien )
              {
                next();
              } else {
                router.push({ path: '/' });
              }


            }else if(stockrole)
            {
              if(to.meta.gerantstock)
              {
                next();
              } else {
                router.push({ path: '/' });
              }

            }
            else if(comptable)
            {
              if(to.meta.comptable)
              {
                next();
              } else {
                router.push({ path: '/' });
              }

            }
          }
        } else {
          next();
        }
  
});

export default router
