<template>
    <main>
        
        <Transition name="wrapModal">
            <wrapModal v-if="wrapModal">
                <formData v-show="cust==true" @close="initialize()"/>
                <formData2 v-show="ser==true" @close="initialize()"/>
            </wrapModal> 
            
        </Transition>
     <div class="p-5">
        <!-- <Loading v-show="isLoading" class="h-full" /> -->
        <h1 class="text-3xl text-center p-2 mt-[4.3rem]">{{ $t('RecieptMsg') }}</h1> 
        
        <div class="grid grid-cols-4 gap-1 max-md:grid-cols-1">
            <!-- input -->
            <div class="">
                <div class="p-3">  
                       <label for="" class="text-gray-500"> Invoice Date :</label>
                       <input type="date" required id="default_standard" disabled v-model="form.date_facturation" class="input-buja-wide "/>

                    </div>
                    <div class="p-3">
                        <button class="p-1 bg-gray-400 flex gap-1 rounded-sm w-auto mt-3 mb-3" @click="toggleModal(),this.$store.state.clientId=null">
                                <span class="material-icons">add</span>
                                <span>Customer</span>
                        </button>
                        <label for="" class="text-gray-500">{{$t('clientMsg')}}</label>
                        <select name="" id="" v-model="form.client_id" required @change="changeSelectState"
                        class="select-buja-wide"> 
                        >
                            <option value=""></option>
                            <option  v-for="client in clients" :key="client.id" :value="client.id">
                                {{ client.name }} /
                                Tel : {{ client.telephone }}
                            </option>
                        </select>
                    </div>     
                    <div class="p-3">
                        <div>
                            <button class="p-1 bg-gray-400 flex gap-1 rounded-sm w-auto mt-3 mb-3" @click="toggleModal2(),this.$store.state.serviceId=null">
                                <span class="material-icons">add</span>
                                <span>Service</span>
                            </button>
                        </div>
        
                       <label for="" class="text-gray-500"> Service :</label>
                        <select name="" id="" v-model="select_service" @change="changeSelectState"
                        class="select-buja-wide"> 
                        >
                            <option  v-for="service in serviceNotSelected" :value="service" :key="service.id">
                                 {{ service.item_designation }} / Price : {{ service.item_price }} #FBU
                            </option>
                        </select>
                    </div>
                    
                    <div class="mt-3 float-right">
                        <button :hidden="isBtnAdd" @click="addService" class="btn-green-add">{{$t('AddMsg')}}</button>
                    </div>
                                    
            </div>
            <!-- table -->
            <div class="col-span-3 w-full "  ref="form-content">
                <router-link to="facture_list" class=" ml-8 rounded-sm p-3 border-2 border-gray-800 hover:bg-gray-400">{{ $t('invoiceListMsg') }} </router-link>
        <Loading v-show="isLoading" class="h-full"/>
            <div class="p-8 border-b border-gray-200  overflow-auto">
                    <table class="divide-y divide-gray-300 w-full">
                        <thead class="bg-black">
                            <tr>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    {{ $t ('designationMsg') }}
                                </th>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    {{$t ('PriceMsg')}}
                                </th>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    {{ $t('QuantityMsg') }}
                                </th>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    <!--{{ $t('QuantityMsg') }}-->Prix HTVA 
                               </th>
                               <th class="px-6 py-2 text-xs text-white capitalize">
                                    <!--{{ $t('QuantityMsg') }}-->Taux TVA 
                               </th>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    <!--{{ $t('QuantityMsg') }}-->Prix TVA 
                                </th>
                                <th class="px-6 py-2 text-xs text-white capitalize">
                                    <!--{{ $t('QuantityMsg') }}-->Prix TVAC 
                                </th>
                                
                                <th class="px-6 py-2 text-xs text-white">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-300">
                            <tr v-for="(item, index) in form.servicesSelections" :key="item.id" class="text-center whitespace-nowrap">
                                <td class="px-6 py-4 text-sm text-gray-500 text-left">
                                    {{ item.item_designation }}
                                </td>
                                <td class="px-6 py-4 text-right">
                                    <div class="text-sm text-gray-900">
                                        
                                        <input type="number" onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))" v-model="item.item_price" min="1" oninput="validity.valid||(value='');" @keyup="changeprice(index)"
                                        class="border-black border-2 focus:border-buja-primary h-[25px] w-[150px]"
                                        />
                                    </div>
                                </td>
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900">
                                        <input type="number" v-model="item.item_quantity" onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))" min="1" oninput="validity.valid || (value='');" @keyup="changeQuantite(index)"
                                        class="border-black border-2 focus:border-buja-primary h-[25px] w-[80px]"
                                        />
                                    </div>
                                </td>
                                <td class="px-6 py-4 text-right">
                                    <div class="text-sm text-gray-900 text-right">
                                        {{ formatNumber(item.item_price_nvat /*form.input_items[index] * item.item_price)*/) }}
                                    </div>
                                </td>
                                <td class="px-6 py-4">        
                                        <select name="" id="" v-model="item.taux_vat" @change="changeVAT(index)" class="border-black border-2 focus:border-buja-primary h-[38px]">
                                            <option value="0">0 %</option>
                                            <option value="10">10 %</option>
                                            <option value="18">18 %</option>
                                        </select>
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500 text-left">
                                    {{ formatNumber(item.vat) }}
                                </td>
                                <td class="px-6 py-4 text-sm text-gray-500 text-left">
                                    {{ formatNumber(item.item_price_wvat /*form.input_items[index] * item.item_price)+item.vat*/)}}
                                </td>
                               
                                <td class="px-6 py-4">
                                    <div class="text-sm text-gray-900">
                                        <button @click="removeItem(item)" class="inline-block text-center">
                                            <span class="material-icons">delete</span>
                                        </button>
                                    </div>
                                </td>

                            </tr>
                            <tr class="">
                                <td colspan="3" class="px-6 py-4 ">
                                    <div class="text-sm text-gray-900 font-bold"> TOTAL HTVA</div>
                                </td>
                                <td colspan="1" class="text-right px-6 py-4">
                                    <div class="text-sm text-gray-900 font-bold"> {{ formatNumber(total?.total_price_nvat) }} </div>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td colspan="3" class="px-6 py-4"><div class="text-sm text-gray-900 font-bold">TOTAL TVAC </div></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td colspan="1" class="text-right px-6 py-4"><div class="text-sm text-gray-900 font-bold ">{{ formatNumber(Math.round(total?.total_price_wvat)) }}</div></td>
                                <td></td>

                            </tr>
                        </tbody>
                    </table>
                    <button :hidden="isBtnSave" :disabled="isLoading" @click="saveInvoince" class="btn-red-empty mt-3 float-right">{{$t ('downlaodMsg')}}</button>
                </div>
        </div>
    </div>
    
 </div>  
</main>
</template>

<script>

import api from '../../../api.js'
import result from '@/mixins/result';
import { mapMutations,mapState } from 'vuex';

export default {
    mixins : [result],
    data() {
        return {
            selected_tva : 0,
            cust:false,
            ser:false,
            form: {
                client_id: null,
                servicesSelections: [],
                input_items: [],
                date_facturation:new Date().toISOString().slice(0,10)
            },
            clients: [],
            services: [],
            isLoading: false,
            isBtnSave:true,
            isBtnAdd:true,
            select_service: null,
            
        };
    },
    unmounted()
    {
        this.$store.state.FactureId=null

    },
    mounted() {
        this.initialize();
        if(this.$store.state.FactureId!==null)
        {
           
         /*  (this.$store.state.Facture.details).forEach(element => {
            this.form.servicesSelections.push(element)
            const index = this.form.servicesSelections.length - 1
           this.form.input_items[index] = element.quantite;
            this.form.servicesSelections[index]['quantite']=element.quantite ;
            this.form.servicesSelections[index]['total'] = this.form.input_items[index] *
            this.form.servicesSelections[index]['amount'];
            this.select_service = null;
            });
            console.log((this.$store.state.Facture.vat))
            this.selected_tva=this.$store.state.Facture.vat
            this.form.client_id=this.$store.state.Facture.client_id
            if(this.$store.state.Facture.date_facture)
            {
                this.form.date_facturation= this.$store.state.Facture.date_facture.substr(0, 10)//new Date(this.$store.state.Facture.date_facture).toISOString().split('T')[0] 

            }
            if (this.form.servicesSelections.length == 0) {
                    this.isBtnSave = true
                }else{
                    this.isBtnSave = false 
                }
            

            console.log(this.$store.state.Facture.date_facture)*/

            
            
        }
    },
    components: {
             'formData2':require('@/components/services/serviceForm.vue').default,
             'formData': require('@/components/clients/clientForm.vue').default,
             'wrapModal':require('@/components/Global/wrapModal.vue').default,
             'Loading': require('@/components/Global/Loading.vue').default,

        },
    
            
        
    methods: {
        changeSelectState(){
            if(this.select_service!=null && this.form.client_id!=""){
                this.isBtnAdd=false
            }else{
                this.isBtnAdd=true
            }
        },
        
        ...mapMutations(['WRAP_MODAl','SIDE_BAR']),
            toggleModal(){
                this.cust=true,
                this.ser=false,
                this.$store.state.FactureId=null
                this.WRAP_MODAl();
            },
            toggleModal2(){
                this.cust=false,
                this.ser=true,
                this.WRAP_MODAl();
            },

        formatNumber(number) {
            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'FBU' }).format(number);
        },
        changeQuantite(index) {
            console.log(this.form.servicesSelections)
            console.log(this.form.input_items[index])
            if (this.form.servicesSelections[index]['item_quantity']=="") {
                this.isBtnSave = true
                this.form.servicesSelections[index]['vat']=0
            }else{
            //this.form.servicesSelections[index]['quantite'] = this.form.input_items[index];
            //this.form.servicesSelections[index]['total'] = this.form.input_items[index] *
            //this.form.servicesSelections[index]['item_price'];
            this.form.servicesSelections[index]['item_price_nvat'] = (this.form.servicesSelections[index]['item_quantity'] *
                this.form.servicesSelections[index]['item_price'])+this.form.servicesSelections[index]['item_ct'];
                this.form.servicesSelections[index]['vat']= ((this.form.servicesSelections[index]['item_quantity'] * this.form.servicesSelections[index]['item_price'])* this.form.servicesSelections[index]['taux_vat']/100)
                this.form.servicesSelections[index]['item_price_wvat']= this.form.servicesSelections[index]['item_price_nvat'] + this.form.servicesSelections[index]['vat']
                this.selected_tva=this.form.servicesSelections[index]['vat']
                this.form.servicesSelections[index]['item_total_amount']=this.form.servicesSelections[index]['item_price_wvat'] + this.form.servicesSelections[index]['item_tl'];
                this.isBtnSave = false

            }
        },
        changeprice(index)
        {
            if (this.form.servicesSelections[index]['item_price'] == "") {
                this.isBtnSave = true
                this.form.servicesSelections[index]['vat']=0
            }else{
                //this.form.servicesSelections[index]['total'] =this.form.input_items[index] *
                //this.form.servicesSelections[index]['item_price'];
                this.form.servicesSelections[index]['item_price_nvat'] = (this.form.servicesSelections[index]['item_quantity'] *
                this.form.servicesSelections[index]['item_price'])+this.form.servicesSelections[index]['item_ct'];
                this.form.servicesSelections[index]['vat']= ((this.form.servicesSelections[index]['item_quantity'] * this.form.servicesSelections[index]['item_price'])* this.form.servicesSelections[index]['taux_vat']/100)
                this.form.servicesSelections[index]['item_price_wvat']= this.form.servicesSelections[index]['item_price_nvat'] + this.form.servicesSelections[index]['vat']
                this.selected_tva=this.form.servicesSelections[index]['vat']
                this.form.servicesSelections[index]['item_total_amount']=this.form.servicesSelections[index]['item_price_wvat'] + this.form.servicesSelections[index]['item_tl'];
                this.isBtnSave = false
            }
            
        },
        changeVAT(index)
        {
            if (this.form.servicesSelections[index]['taux_vat'] =="0") {
                this.form.servicesSelections[index]['vat']=0
                this.form.servicesSelections[index]['item_price_wvat']= this.form.servicesSelections[index]['item_price_nvat'] + this.form.servicesSelections[index]['vat']
            }else{
                //this.form.servicesSelections[index]['total'] =this.form.input_items[index] *
                //this.form.servicesSelections[index]['item_price'];
                this.form.servicesSelections[index]['item_price_nvat'] = (this.form.servicesSelections[index]['item_quantity'] *
                this.form.servicesSelections[index]['item_price'])+this.form.servicesSelections[index]['item_ct'];
                this.form.servicesSelections[index]['vat']= ((this.form.servicesSelections[index]['item_quantity'] * this.form.servicesSelections[index]['item_price'])* this.form.servicesSelections[index]['taux_vat']/100)
                this.form.servicesSelections[index]['item_price_wvat']= this.form.servicesSelections[index]['item_price_nvat'] + this.form.servicesSelections[index]['vat']
                this.selected_tva=this.form.servicesSelections[index]['vat']
                this.form.servicesSelections[index]['item_total_amount']=this.form.servicesSelections[index]['item_price_wvat'] + this.form.servicesSelections[index]['item_tl'];
                this.isBtnSave = false
            }
            console.log('vat');
            
        },
        saveInvoince() {
            
            console.log(this.form.client_id)
            if (this.form.servicesSelections.length==0 || !this.form.client_id) return this.errorAlert('certain données ne sont pas bien remplit !! verifier que vous avez choisit un client ou des services ');
            this.isLoading = true;
            if(this.$store.state.FactureId==null)
            {
                 api.post("factures", {
                data: this.form.servicesSelections,
                client_id: this.form.client_id,
                //client_id: this.form.client_id,
                total: this.total,
                date_facturation:this.form.date_facturation
            })
                .then(resp => {
                    //console.log(resp.data.msg)
                if(resp.data?.obr.success==true)
                {
                    this.successAllert(resp.data.obr.msg);
                    this.isLoading = false;
                    this.form.servicesSelections=[]

                }
                else if(resp.data?.obr.success==false && resp.data?.obr.msg=="Vérifier que votre ordinateur est connecté"){
                    this.infoAllert("Facture enregistre mais sera envoyé ulterierement a l'obr " + ' '+ resp.data.obr.msg);
                    this.isLoading = false;
                }
                else if(resp.data?.obr.success==false){
                    this.errorAlert(resp.data.obr.msg);
                    this.isLoading = false;
                 }

                
                
                }).catch(err => {
                    this.isLoading = false;
                    this.errorAlert(err.data.message);
                    console.log(err.data.message);
                    
                });

            }
            else{
                api.put("factures/"+ this.$store.state.FactureId ,{
                    data: this.form.servicesSelections,
                    client_id: this.form.client_id,
                    total: this.total,
                    vat: this.selected_tva,
                    date_facturation:this.form.date_facturation
            })
                .then(resp => {
                    //console.log(resp.data.msg)
                this.successAllert(resp.data.msg);
                
                }).catch(err => {
                    this.errorAlert();
                    console.log(err);
                });

            }
           
        },
        removeItem(item) { 
            this.form.servicesSelections = this.form.servicesSelections.filter(it => it.id != item.id);
            if (this.form.servicesSelections.length == 0) {
                this.isBtnSave = true
            }else{
                this.isBtnSave = false 
            }
        },
        addService() { 
            this.form.servicesSelections.push(this.select_service)
            const index = this.form.servicesSelections.length - 1
            this.form.input_items[index] = 1;

            //this.form.servicesSelections[index]['quantite'] = 1;

            this.form.servicesSelections[index]['item_price_nvat'] = this.form.servicesSelections[index]['item_quantity'] *
            this.form.servicesSelections[index]['item_price'] + this.form.servicesSelections[index]['item_ct'];
            this.form.servicesSelections[index]['item_total_amount']=this.form.servicesSelections[index]['item_price_wvat'] + this.form.servicesSelections[index]['item_tl'];



            this.select_service = null;
            this.isBtnSave = false
            this.isBtnAdd = true
            console.log(this.form.servicesSelections)
            //console.log(this.form.input_items[index])

        },
        optionDisplayText() {
            return "OptionDisplayText ";
        },
        initialize() {
            api.get("/client")
            .then(resp => {
                this.clients = resp.data;
            })
            .catch(err => {
                console.error(err);
            });
            api.get("/service")
            .then(resp => {
                this.services = resp.data;
            })
            .catch(err => {
                console.error(err);
            });
        },
      
    },
    computed: {
        
        ...mapState(['wrapModal','articleId','article']),
        total() { 
           let item_price_wvat=0;
            
            let htva = 0;
            
            let Total_amount=0;
            let total_vat=0;

            for (var i = 0; i < this.form.servicesSelections.length; i++) {

                
                ///item_price_wvat=(this.form.servicesSelections[i].item_price *1) *  this.form.input_items[i] +this.form.servicesSelections[i].vat
                htva += (this.form.servicesSelections[i].item_price *1) *  this.form.servicesSelections[i].item_quantity;
                total_vat += this.form.servicesSelections[i].vat;
                Total_amount+=((this.form.servicesSelections[i].item_price *1) * this.form.servicesSelections[i].item_quantity) + this.form.servicesSelections[i].vat
            }

             
            return  {
                total_price_nvat:htva,
                total_price_wvat:Total_amount,
                total_vat: total_vat,
                    //TVA: this.form.servicesSelections[i].vat,
                   // TVAC: (htva + tva),
            }
        },
        serviceNotSelected() {

            const idsSelected = [];

            for (const x of this.form.servicesSelections){
                idsSelected.push(x.id);
            }
            console.log(idsSelected);

            const items = this.services?.data?.filter(item =>
                !idsSelected.includes(item?.id))
            
            return items;
        },
    }
    
}
</script>

<style lang="scss" scoped>
.material-icons {
font-variation-settings:
'FILL' 0,
'wght' 100,
'GRAD' -25,
'opsz' 40
}

// animated modal
.wrapModal-enter-active,
.wrapModal-leave-active {
    transition: 0.8s ease all;
    position: relative;
    z-index: 99;
}

.wrapModal-enter-from,
.wrapModal-leave-to {
    position: relative;
    z-index: 99; 
    transform: translateX(700px); 
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>