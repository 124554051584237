<template>
    <div class="navbar no_print">
        <!-- hearder -->
           <header class=" sticky top-0 shadow border-black">
            <nav class="flex justify-end">
                <ul class="flex items-end justify-between text-white">
                    <li>
                      <div class="flex justify-center">
                        <div>
                          <div class="relative" data-te-dropdown-ref>
                            <button class="flex items-center whitespace-nowrap mr-5 rounded px-6 pt-2.5 pb-2 text-xs font-medium capitalize leading-normal text-white  transition duration-150 ease-in-out motion-reduce:transition-none"
                            type="button"  id="dropdownMenuButton1" data-te-dropdown-toggle-ref aria-expanded="false"
                              data-te-ripple-init data-te-ripple-color="light">
                              <img src="@/assets/bujaEvent.jpg" alt="Dukore Logo" srcset="" class="w-10 h-10 rounded-full">
                              <span class="text-black text-lg ml-1">Patrick Irakoze</span>
                                <span class="ml-1 w-2">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="black" class="h-5 w-5">
                                    <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                      clip-rule="evenodd" /> </svg>
                                </span>
                            </button>
                            <ul class="absolute z-[1000] w-56 float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
                              aria-labelledby="dropdownMenuButton1"  data-te-dropdown-menu-ref>
                              <!-- <li>
                                <a class="flex w-full whitespace-nowrap bg-transparent py-2 px-4 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                                  href="#" data-te-dropdown-item-ref >
                                  <span class="material-icons">person</span>
                                  <span class="ml-2">{{$t("ProfileMsg")}}</span>
                                </a>
                              </li> -->
                              <li>
                                <router-link to="/settings" class="flex w-full whitespace-nowrap bg-transparent py-2 px-4 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                                  href="#" data-te-dropdown-item-ref >
                                  <span class="material-icons">settings</span>
                                  <span class="ml-2">{{ $t('settingMsg') }}</span>
                                </router-link>
                              </li>
                              <li>
                                <router-link to="" @click="logout" class="flex w-full whitespace-nowrap bg-transparent py-2 px-4 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-neutral-600"
                                  href="#" data-te-dropdown-item-ref >
                                  <span class="material-icons">logout</span>
                                  <span class="ml-2">{{$t ('logoutMsg')}}</span>
                                </router-link> 
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                </ul>
            </nav>
        </header>
    <!-- hearder -->
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
    data () {

        return {
          user:JSON.parse(this.$store.getters.user)
        }
    },
    methods:{
      ...mapMutations(['LOGOUT']),
		logout() {
       this.LOGOUT()
        // localStorage.removeItem('token')
        // this.$router.push('/')
        
    }
	
	}
}
</script>

<style lang="scss" scoped>
@media print {
    .no_print{
        display: none;
    }
}
.navbar{
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 99;
}
</style>