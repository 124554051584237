<template>
    <div :class="`${loading ? 'buja-form-content' : ' buja-form-content'}`" ref="form-content" class="FormCl">
     <!-- <div class="buja-form-content FormCl" ref="form-content"> -->
    <Loading v-show="loading" class="h-full"/>
      <h1 class=" text-3xl flex justify-center mb-3 ">{{ $t ('outputArticleMsg') }}</h1>
      <form action="" @submit.prevent="saveInformation" ref="locationForm">
      <div class="pt-5 max-w-sm rounded overflow-hidden shadow-lg">
        <div class="p-3">
            <label for="">{{ $t ('Categories') }}</label>
            <select name="" id=""  @keypress.enter="change" v-model="category" class="py-2 px-1 w-full bg-[#141625] border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]">
              <option class="hover:bg-green-300" v-for="category in categories" :key="category.id" :value="category"> {{ category.name }} </option>
            </select> 
          </div>
        <h5>{{$t ('EquipmentMsg')}}</h5>
        <div class="flex justify-between">
         
          <div class=" p-3">
            <label for="">{{ $t ('designationMsg') }} :</label>
            <select name=""  id="" v-model="form.article_id" class="py-2 px-1 w-full bg-[#141625] border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]">
              <option v-for="article in filtereditem"  :key="article.id" :value="article" class="hover:bg-green-300">
                {{article.name}}
              </option>
            </select>              
          </div> 
          <div class="p-3">
            <label for="">{{ $t ('QuantityMsg') }}</label>
            <input type="number"  :placeholder="$t ('QuantityMsg')" v-model="form.amount"
             class="py-2 px-1 w-full bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"> 
             <div>{{ errors?.amount }}</div> 
          </div>
          <div>
              <span @click="addNewDetail" class="cursor-pointer border-2 border-white text-white  font-[5px] rounded-[3px] w-10 bg-transparent text-center p-1">{{$t ('AddMsg')}}</span>
          </div>
        </div>
        <div class="p-3" v-for="item in autres" :key="item.id">
            <div class="flex justify-between align-baseline">
                <div class="">
                  <h6>{{$t('nameMsg')}}</h6>
                  <h6>{{$t ('QuantityMsg')}}</h6>
                </div>
                <div class="">
                  <h6>{{ item.name }}</h6>
                  <h6>{{ item.quantity }}</h6> 
                </div>
                <button @click="deleteDetail(item.id)" class="cursor-pointer outline-none border-none text-white mr-[8px] font-[5px] rounded-[3px] w-10 bg-transparent text-center p-1 mt-2">X</button>
              </div>
         </div>
      </div>
         
      <div class="flex gap-8 mt-8">
                <button type="submit" class=" rounded-sm p-3 bg-green-600 hover:bg-green-400">Enregistrer</button>
                <button type="reset" class=" rounded-sm p-3 bg-red-600 hover:bg-red-400">{{ $t ('btnEmpty') }}</button>
                <div  @click="toggleModal" class="rounded-sm p-3 bg-gray-600  cursor-pointer hover:bg-gray-400 justify-end">{{ $t ('btnClose') }}</div>
              </div>
      </form>
      
       
  </div> 
  
</template>

<script>
import api from '../../../api.js'
import result from '@/mixins/result'
import { mapMutations,mapState } from 'vuex';
export default {
  mixins:[result],

    data()
    {
        return{
            form:{
              
              article_id:'',
              id:this.$store.state.locationId,
              articles:[],
              op:'new',
          },
          articles:[],
          articlesMod:[],
          autres:[],
          loading : null,
          category:'',
          categories:[],
        }
    },
    mounted(){ 
        this.getarticles()
        this.getCategory()
        this.articlesMod=this.$store.state.locations_art   
    },
    components:{
          'Loading':require('../Global/Loading.vue').default
      },
      computed:{
        filtereditem()
      {
        return this.change(this.articles, this.category.id)
      }
      },
      methods:{
        change(articles,id)
              {
                if(Array.isArray(articles) ){
                return articles.filter((item)=> item.category_id==id
                        )} 
                console.log(this.articles)
                return articles;

              },
              getCategory() {
              
              api.get("/category")
              .then(resp => {
                  this.categories = resp.data
                  console.log(this.categories)
              })
              .catch(err => {
                  console.error(err)
              })
        },
        ...mapMutations(['WRAP_MODAl','TOGGLE_MODAL']),
  
                toggleModal(){
                    this.WRAP_MODAl(); 
                    //this.$store.state.clientId=null; 
                    this.$emit('close')
                },
                SaveData(){
                    this.loading=true;
                },
                getarticles() {
                  api.get("/article")
                  .then(resp => {
                      this.articles = resp.data
                      console.log(this.articles)
                  })
                  .catch(err => {
                      console.error(err)
                  })
               },
                addNewDetail() {
                  this.autres=[]
                let result1=this.articlesMod.filter((item)=>item.id==this.form.article_id.id);
                let result= this.autres.filter((item)=>item.id==this.form.article_id.id);
                let result2= this.articles.find((item) => item.id == this.form.article_id.id)
      
                 console.log(result2)
                  if(result1.length==0 && result.length==0)
                  {
                  if(this.form.amount>result2.quantity)
                  {
                    alert('quantité pas disponible')
                  }
                  else{
                    this.autres.push({
                    id: this.form.article_id.id,
                    name:this.form.article_id.name,
                    quantity: this.form.amount,
                    addedby:{id:this.getuserlogged().id,name:this.getuserlogged().name}
                  });
                  
                  }
                  console.log(this.autres)    
                  }
                  else{
                    
                      alert('Equipment a été deja ajouté')
                    
                  }

              },
              deleteDetail(id) {
                 this.autres = this.autres.filter((item) => item.id !== id);
                },
                saveInformation()
            {
            this.form.articles=this.autres
            this.loading = true;
            api.patch(
                "/location/" +this.form.id,
                this.form)
                .then((resp) => {

                  //this.locations = resp.data;
                  this.loading = false;
                  this.successAllert()
                  this.$store.state.newarticles=this.form.articles
                  this.$store.state.isnew=true
                  console.log(this.$store.state.newarticles)
                  this.autres=[]
                  this.form={articles:[]}    
                  //  this.$store.state.wrapModal = null;
                })
                .catch((err) => {
                  this.loading = false;
                  this.errorAlert()
                  console.error(err.response.data.errors);
                  this.errors = err.response.data.errors;
                  
                });

            }
            
              
      }

}
</script>
<style>

</style>