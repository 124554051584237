<template>
    <main>
    <!-- <div @click="checkClick" class="flex flex-col fixed right-0 top-0 items-end w-full h-[100vh] sm:absolute z-[99] wrap-mod" ref="wrapModal"> -->
    <div @click="checkClick" class="buja-wrap-modal wrap-mod" ref="wrapModal">
   
       <slot/>
            
    </div>
</main>
</template>

<script>
import { mapMutations } from 'vuex';

export default{
  data(){
    return{
      
    }

  },
  methods:{
    ...mapMutations(['WRAP_MODAl','TOGGLE_MODAL']),

    // check outside click on modal
    checkClick(e){
      if(e.target==this.$refs.wrapModal){
          this.TOGGLE_MODAL();
      }
    }

  },

}

</script>

<style lang="scss" scoped>

.wrap-mod{
  z-index: 99;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1024px) {
			width:100%;
		}

}

</style>