<template>
    <div class="w-full mt-20">

        <div>
            <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
                <ul class="flex flex-wrap -mb-px text-sm font-medium text-center" id="myTab"
                    data-tabs-toggle="#myTabContent" role="tablist">
                    <li class="mr-2" role="presentation">
                        <button class="flex p-4 border-b-2 rounded-t-lg" id="profile-tab" data-tabs-target="#profile"
                            type="button" role="tab" aria-controls="profile" aria-selected="true">
                            <svg aria-hidden="true"
                                class="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                                fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                                    clip-rule="evenodd"></path>
                            </svg>Profile
                        </button>
                    </li>

                    <!-- copier coller -->
                    <li class="mr-2" role="presentation">
                        <button
                            class="flex p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                            id="settings-tab" data-tabs-target="#settings" type="button" role="tab" aria-controls="settings"
                            aria-selected="false">
                            <svg aria-hidden="true"
                                class="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                                fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z">
                                </path>
                            </svg>
                            Langue
                        </button>
                    </li>
                    <li class="mr-2" role="presentation">
                        <button
                            class="flex align-baseline items-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                            id="company-tab" data-tabs-target="#company" type="button" role="tab" aria-controls="company"
                            aria-selected="false">
                            <span class="material-icons">house</span>
                            <span>Entreprise</span>
                        </button>
                    </li>
                    <!-- end copier coller -->
                </ul>
            </div>
            <div id="myTabContent" class=" h-screen bg-gray-50">
                <div class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800" id="profile" role="tabpanel"
                    aria-labelledby="profile-tab">
                    <div
                        class="w-full max-w-sm mx-auto bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <div class="flex justify-end px-4 pt-4">
                            <button id="dropdownButton" data-dropdown-toggle="dropdown"
                                class="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
                                type="button">
                                <span class="sr-only">Open dropdown</span>
                                <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z">
                                    </path>
                                </svg>
                            </button>

                            <div id="dropdown"
                                class="z-10 hidden text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                <ul class="py-2" aria-labelledby="dropdownButton">
                                    <li v-if="this.edit==false">
                                        <a @click="editable" href="#"
                                            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Modifier</a>
                                    </li>
                                    <li v-else>
                                        <a @click="this.edit=false" href="#"
                                            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Fermer</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div v-if="edit" class="my-4">
                            <form action="" @submit.prevent="saveinfo" ref="userform">
                                <div class=" p-3">
                                    <img v-if="image" :src="image" alt="" srcset="" class="mx-auto w-20 h-20 rounded-full">
                                    <input @change="onFileSelected" type="file">
                                </div>
                                <div class="p-3">
                                    <div class="relative z-0">
                                        <input type="text" required id="default_standard" v-model="form.name"
                                            class="input-buja-wide peer" placeholder=" " />
                                        <label for="default_standard" class="float-label-buja">{{ $t('nameMsg') }} </label>
                                    </div>
                                    <div>{{ errors?.name }}</div>
                                </div>
                                <div class="p-3">
                                    <div class="relative z-0">
                                        <input type="text" required id="default_standard" v-model="form.username"
                                            class="input-buja-wide peer" placeholder=" " />
                                        <label for="default_standard" class="float-label-buja">{{ $t('usernameMsg') }}
                                        </label>
                                    </div>
                                    <div>{{ errors?.username }}</div>
                                </div>
                                <div class="p-3">
                                    <div class="relative z-0">
                                        <input type="email" required id="default_standard" v-model="form.email"
                                            class="input-buja-wide peer" placeholder=" " />
                                        <label for="default_standard" class="float-label-buja">{{ $t('Email') }} </label>
                                    </div>
                                    <div>{{ errors?.email }}</div>
                                </div>
                                <div class="p-3">
                                    <div class="relative z-0">
                                        <input type="password" required id="default_standard" v-model="form.password"
                                            class="input-buja-wide peer" placeholder=" " />
                                        <label for="default_standard" class="float-label-buja">{{ $t('oldpasswordMsg') }}
                                        </label>
                                    </div>
                                    <div>{{ errors?.password }}</div>
                                </div>
                                <div class="p-3">
                                    <div class="relative z-0">
                                        <input type="password" required id="default_standard" v-model="form.newpassword"
                                            class="input-buja-wide peer" placeholder=" " />
                                        <label for="default_standard" class="float-label-buja">{{ $t('NewpasswordMsg') }}
                                        </label>
                                    </div>
                                    <div>{{ errors?.newpassword }}</div>
                                </div>
                                <div class="p-3">
                                    <div class="relative z-0">
                                        <input type="password" required id="default_standard"
                                            v-model="form.password_confirmation" class="input-buja-wide peer"
                                            placeholder=" " />
                                        <label for="default_standard" class="float-label-buja">{{ $t('confirmpasswordMsg')
                                        }} </label>
                                    </div>
                                    <div>{{ errors?.password_confirmation }}</div>
                                </div>
                                <div class="flex gap-8 mt-5">
                                    <button type="submit" class="btn-green-add">{{ $t('btnEdit') }}</button>
                                    <button type="reset" class="btn-red-empty">{{ $t('btnCancel') }}</button>
                                </div>
                            </form>
                        </div>
                        <div class="flex flex-col items-center pb-10" v-else>
                            <img class="w-24 h-24 mb-3 rounded-full shadow-lg" :src="profile" alt="Bonnie image" />
                            <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">{{ name }}</h5>
                            <span class="text-sm text-gray-500 dark:text-gray-400">{{ email }}</span>
                        </div>
                    </div>
                </div>
                <div class="hidden p-4 rounded-lg bg-gray-50" id="settings" role="tabpanel" aria-labelledby="settings-tab">

                    <div class="text-xl text-center mt-3">{{ $t('selectLanguage') }}</div>
                    <div class="flex gap-3 justify-center mt-3">
                        <button class="btn" v-for="entry in languages" :key="entry.title"
                            @click="changeLocale(entry.language)">
                            <flag :iso="entry.flag" :squared=false /> {{ entry.title }} |
                        </button>
                    </div>
                </div>

                <!-- copier coller -->
                <div class="hidden rounded-lg bg-gray-50 dark:bg-gray-800" id="company" role="tabpanel"
                    aria-labelledby="company-tab">
                    <div class="px-8 bg-white border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                        <div class="flex justify-end px-4 pt-4">
                            <span v-if="this.isEditCompany" class="flex gap-3">
                                <button  @click="editCompany"  class="btn-green-add flex gap-2 items-center">
                                    <Loading v-if="this.loading"/>
                                    <span>Enregistrer</span>
                                </button>
                                <button class=" btn-red-empty" @click="this.isEditCompany = false">Annuler</button>
                            </span>

                            <button v-else @click="this.isEditCompany = true"
                                class="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
                                type="button">
                                Edit
                            </button>

                            <!-- <div id="dropdown2" v-if="this.isEditCompany==false"
                                class="z-10 hidden text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                <ul class="py-2" aria-labelledby="dropdownButton2">
                                    <li>
                                        <a @click="this.isEditCompany = true" href="#"
                                            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Edit</a>
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                        <div>
                            <!-- <div class="p-5">
                                <img class="w-24 h-24 mb-3 rounded-full shadow-lg" src="@/assets/logo.png" alt="Bonnie image" />
                            </div> -->
                            <form action="" class="p-5 flex max-lg:block justify-between">
                                <div class="">
                                    <div class="flex  mb-1 justify-between items-center">
                                        <div class="text-lg">Entreprise :</div>
                                        <div class="text-gray-500 dark:text-gray-400 pl-10">{{ entreprise.tp_name }}</div>
                                    </div>
                                    <div class="flex mb-1 justify-between items-center">
                                        <div class="text-lg">Type :</div>
                                        <div class="text-gray-500 dark:text-gray-400 pl-10" v-if="entreprise.tp_type == '1'">
                                            Personne physique</div>
                                        <div class="text-gray-500 dark:text-gray-400 pl-10" v-else>Personne Morale</div>
                                    </div>
                                    <div class="flex  mb-1 justify-between items-center">
                                        <div class="text-lg">NIF :</div>
                                        <div class="text-gray-500 dark:text-gray-400 pl-10">{{ entreprise.tp_TIN }}</div>
                                    </div>
                                    <div class="flex  mb-1 justify-between items-center">
                                        <div class="text-lg">N॰ de registre de commerce :</div>
                                        <input v-if="isEditCompany" type="text" disabled required id="default_standard"
                                            v-model="entreprise.tp_trade_number" class="input-company peer"
                                            placeholder=" " />
                                        <div v-else class="text-gray-500 dark:text-gray-400 pl-10">{{
                                            entreprise.tp_trade_number }}</div>
                                    </div>
                                    <div class="flex  mb-1 justify-between items-center">
                                        <div class="text-lg">Boîte postal :</div>
                                        <input v-if="isEditCompany" type="text" required id="default_standard"
                                            v-model="entreprise.tp_postal_number" class="input-company peer"
                                            placeholder=" " />
                                        <div v-else class="text-gray-500 dark:text-gray-400 pl-10">{{
                                            entreprise.tp_postal_number }}</div>
                                    </div>
                                    <div class="flex  mb-1 justify-between items-center">
                                        <div class="text-lg">Numéro de téléphone :</div>
                                        <input v-if="isEditCompany" type="text" required id="default_standard"
                                            v-model="entreprise.tp_phone_number" class="input-company peer"
                                            placeholder=" " />
                                        <div v-else class="text-gray-500 dark:text-gray-400 pl-10">{{
                                            entreprise.tp_phone_number }}</div>
                                    </div>
                                    <div class="flex  mb-1 justify-between items-center">
                                        <div class="text-lg">Province :</div>
                                        <input v-if="isEditCompany" type="text" required id="default_standard"
                                            v-model="entreprise.tp_address_privonce" class="input-company peer"
                                            placeholder=" " />
                                        <div v-else class="text-gray-500 dark:text-gray-400 pl-10">{{
                                            entreprise.tp_address_privonce }}</div>
                                    </div>
                                    <div class="flex  mb-1 justify-between items-center">
                                        <div class="text-lg">Commune :</div>
                                        <input v-if="isEditCompany" type="text" required id="default_standard"
                                            v-model="entreprise.tp_address_privonce" class="input-company peer"
                                            placeholder=" " />
                                        <div v-else class="text-gray-500 dark:text-gray-400 pl-10">{{
                                            entreprise.tp_address_privonce }}</div>
                                    </div>
                                    <div class="flex  mb-1 justify-between items-center">
                                        <div class="text-lg">Quartier :</div>
                                        <input v-if="isEditCompany" type="text" required id="default_standard"
                                            v-model="entreprise.tp_address_quartier" class="input-company peer"
                                            placeholder=" " />
                                        <div v-else class="text-gray-500 dark:text-gray-400 pl-10">{{
                                            entreprise.tp_address_quartier }}</div>
                                    </div>
                                    <div class="flex  mb-1 justify-between items-center">
                                        <div class="text-lg">Avenue :</div>
                                        <input v-if="isEditCompany" type="text" required id="default_standard"
                                            v-model="entreprise.tp_address_avenue" class="input-company peer"
                                            placeholder=" " />
                                        <div v-else class="text-gray-500 dark:text-gray-400 pl-10">{{
                                            entreprise.tp_address_avenue }}</div>
                                    </div>
                                </div>
                                <div class="">
                                    <div class="flex  mb-1 justify-between items-center">
                                        <div class="text-lg">Rue :</div>
                                        <input v-if="isEditCompany" type="text" required id="default_standard"
                                            v-model="entreprise.tp_address_rue" class="input-company peer"
                                            placeholder=" " />
                                        <div v-else class="text-gray-500 dark:text-gray-400 pl-10">{{
                                            entreprise.tp_address_rue }}</div>
                                    </div>
                                    <div class="flex  mb-1 justify-between items-center">
                                        <div class="text-lg">Numéro :</div>
                                        <input v-if="isEditCompany" type="text" required id="default_standard"
                                            v-model="entreprise.tp_address_number" class="input-company peer"
                                            placeholder=" " />
                                        <div v-else class="text-gray-500 dark:text-gray-400 pl-10">{{
                                            entreprise.tp_address_number }}</div>
                                    </div>
                                    <div class="flex mb-1 justify-between items-center">
                                        <div class="text-lg">TVA :</div>
                                        <select v-if="isEditCompany" name="" required id="" v-model="entreprise.vat_taxpayer" class="select-company">
                                            <option value="1" class="">Oui</option>
                                            <option value="0" class="">Non</option>
                                        </select>
                                        <div v-else>
                                            <div class="text-gray-500 dark:text-gray-400 pl-10"
                                                v-if="entreprise.vat_taxpayer == '1'">Oui</div>
                                            <div class="text-gray-500 dark:text-gray-400 pl-10" v-else>Non</div>
                                        </div>
                                    </div>
                                    <div class="flex  mb-1 justify-between items-center">
                                        <div class="text-lg">Assujetti à la taxe de consommation :</div>
                                        <select v-if="isEditCompany" name="" required id="" v-model="entreprise.ct_taxpayer" class="select-company">
                                            <option value="1" class="">Oui</option>
                                            <option value="0" class="">Non</option>
                                        </select>
                                        <div v-else>
                                            <div class="text-gray-500 dark:text-gray-400 pl-10"
                                                v-if="entreprise.ct_taxpayer == '1'">Oui</div>
                                            <div class="text-gray-500 dark:text-gray-400 pl-10" v-else>Non</div>
                                        </div>
                                    </div>
                                    <div class="flex  mb-1 justify-between items-center">
                                        <div class="text-lg">Assujetti au prélèvement forfaitaire libératoire :</div>
                                        <select v-if="isEditCompany" name="" required id="" v-model="entreprise.tl_taxpayer" class="select-company">
                                            <option value="1" class="">Oui</option>
                                            <option value="0" class="">Non</option>
                                        </select>
                                        <div v-else>
                                            <div class="text-gray-500 dark:text-gray-400 pl-10"
                                                v-if="entreprise.tl_taxpayer == '1'">Oui</div>
                                            <div class="text-gray-500 dark:text-gray-400 pl-10" v-else>Non</div>
                                        </div>
                                    </div>
                                    <div class="flex  mb-1 justify-between items-center">
                                        <div class="text-lg">Le Centre fiscal :</div>
                                        <select v-if="isEditCompany" name="" required id="" v-model="entreprise.tp_fiscal_center" class="select-company">
                                            <option  class="">DGC</option>
                                            <option  class="">DMC</option>
                                            <option  class="">DPMC</option>
                                        </select>
                                        <div v-else class="text-gray-500 dark:text-gray-400 pl-10">{{ entreprise.tp_fiscal_center
                                        }}</div>
                                    </div>
                                    <div class="flex  mb-1 justify-between items-center">
                                        <div class="text-lg">Le Secteur d'activité :</div>
                                        <input v-if="isEditCompany" type="text" required id="default_standard"
                                            v-model="entreprise.tp_activity_sector" class="input-company peer"
                                            placeholder=" " />
                                        <div v-else class="text-gray-500 dark:text-gray-400 pl-10">{{ entreprise.tp_activity_sector
                                        }}</div>
                                    </div>
                                    <div class="flex  mb-1 justify-between items-center">
                                        <div class="text-lg">La Forme jurdique :</div>
                                        <input v-if="isEditCompany" type="text" required id="default_standard"
                                            v-model="entreprise.tp_legal_form" class="input-company peer"
                                            placeholder=" " />
                                        <div v-else class="text-gray-500 dark:text-gray-400 pl-10">{{ entreprise.tp_legal_form }}
                                        </div>
                                    </div>
                                    <div class="flex  mb-1 justify-between items-center">
                                        <div class="text-lg">Type de paiement :</div>
                                        <select v-if="isEditCompany" name="" required id="" v-model="entreprise.payment_type" class="select-company">
                                            <option value="1" class="">En espèce</option>
                                            <option value="2" class="">Banque</option>
                                            <option value="3" class="">A crédit</option>
                                            <option value="4" class="">Autres</option>
                                        </select>
                                        <div v-else>
                                            <div v-if="entreprise.payment_type==1" class="text-gray-500 dark:text-gray-400 pl-10">En espèce</div>
                                            <div v-if="entreprise.payment_type==2" class="text-gray-500 dark:text-gray-400 pl-10">Banque</div>
                                            <div v-if="entreprise.payment_type==3" class="text-gray-500 dark:text-gray-400 pl-10">A crédit</div>
                                            <div v-if="entreprise.payment_type==4" class="text-gray-500 dark:text-gray-400 pl-10">Autres</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-5">




                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                <!-- end copier coller -->
            </div>
        </div>

        <!-- <ul
  class="mb-5 flex list-none flex-col flex-wrap border-b-0 pl-0 md:flex-row"
  role="tablist"
  data-te-nav-ref>
  <li role="presentation">
    <a
      href="#tabs-home2"
      class="my-2 block border-x-0 border-t-0 border-b-2 border-transparent px-7 pt-4 pb-3.5 text-sm font-medium uppercase leading-tight text-black-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-success data-[te-nav-active]:text-success dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:text-success"
      data-te-toggle="pill"
      data-te-target="#tabs-home2"
      data-te-nav-active
      role="tab"
      aria-controls="tabs-home2"
      aria-selected="true"
      >Pending</a
    >
  </li>
  <li role="presentation">
    <a
      href="#tabs-profile2"
      class="focus:border-transparen my-2 block border-x-0 border-t-0 border-b-2 border-transparent px-7 pt-4 pb-3.5 text-sm font-medium uppercase leading-tight text-black-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate data-[te-nav-active]:border-success data-[te-nav-active]:text-success dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:text-success"
      data-te-toggle="pill"
      data-te-target="#tabs-profile2"
      role="tab"
      aria-controls="tabs-profile2"
      aria-selected="false"
      >Approuved</a
    >
  </li>
  </ul> -->

        <!-- <div>

            <div
    class="hidden opacity-0  transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
    id="tabs-home2"
    role="tabpanel"
    aria-labelledby="tabs-home-tab2"
    data-te-tab-active>
    kkkkkk
  </div>
  
  <div
    class="hidden opacity-0 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
    id="tabs-profile2"
    role="tabpanel"
    aria-labelledby="tabs-profile-tab2">
    hhhhhhh

    </div>

        
        </div> -->
    </div>
</template>

<script>
import i18n from '@/plugins/i18n';
import api from '../../api'
import result from '../mixins/result'
export default {
    mixins: [result],
    data() {
        return {
            languages: [
                { flag: 'us', language: 'en', title: 'English' },
                { flag: 'fr', language: 'fr', title: 'French' }
            ],
            entreprise: { //copy past
                id:"",   
                tp_name: "",
                tp_type: "",
                tp_TIN: "",
                tp_trade_number: "",
                tp_postal_number: "",
                tp_phone_number: "",
                tp_address_privonce: "",
                tp_address_quartier: "",
                tp_address_commune: "",
                tp_address_number: "",
                vat_taxpayer: "",
                tp_address_avenue: "",
                tp_address_rue: "",
                ct_taxpayer: "",
                tl_taxpayer: "",
                tp_fiscal_center: "",
                tp_activity_sector: "",
                tp_legal_form: "",
                payment_type: "",
            }, 
            form: {
                name: "",
                username: "",
                email: "",
                password: "",
                newpassword: "",
                password_confirmation: "",
                image: "",
            },
            users: [],
            errors: [],
            name: '',
            edit: false,
            isEditCompany: false, // copy past
            email: '',
            image: null,
            profile: '',
            username: '',
            id: '',
            status: '',
            loading:null
        };
    },

    components: {
        'Loading': require('@/components/Global/buttonLoading.vue').default
    },
    mounted() {
        this.fetchData() // copy past
        this.getuser()
    },
    methods: {
        editable() {
            this.edit = true
            this.form.password=""
            this.form.newpassword=""
            this.form.password_confirmation=""
            
        },
        // copie past
        editCompany(){
            this.loading = true;
            api.patch(
                    "/entreprise/" + this.entreprise.id,
                    this.entreprise)
                    .then((resp) => {
                        this.loading = false;
                        this.isEditCompany=false
                        this.successAllert()
                        this.fetchData()
                    })
                    .catch((err) => {
                        console.error(err.response.data.errors);
                        this.errors = err.response.data.errors;
                        this.errorAlert()
                        this.loading = false;
                    });
        },
        onFileSelected(event) {
            console.log(event);
            this.form.image = event.target.files[0]
            // console.log(this.form.image);
            this.image = URL.createObjectURL(event.target.files[0]);
        },
        onFileChange(event) {
            const file = event.target.files[0];
            this.form.image = event.target.files[0]
            this.image = URL.createObjectURL(file);
        },

        getuser() {
            let userlogged = JSON.parse(localStorage.getItem('userBujEvent'))
            this.name = Object.values(userlogged)[0].name
            this.profile = Object.values(userlogged)[0].image
            this.email = Object.values(userlogged)[0].email
            this.id = Object.values(userlogged)[0].id
            this.form.name = Object.values(userlogged)[0].name
            this.image = Object.values(userlogged)[0].image
            this.form.email = Object.values(userlogged)[0].email
            this.form.username = Object.values(userlogged)[0].username
            this.id = Object.values(userlogged)[0].id
        },

        changeLocale(locale) {
            localStorage.setItem('LocalLanguage', locale);
            this.$router.go(0)
            i18n.locale = locale;
            console.log(locale);
        },

        saveinfo()
            {
                this.loading=true
                const data={old_password:this.form.password,
                new_password:this.form.newpassword,
                new_password_confirmation:this.form.password_confirmation

                }
                api.post(
                    "/changepassword/",data
                    )
                    .then((resp) => {
                    //    this.users = resp.data;
                        this.loading = false;
                        this.successAllert();
                        this.edit=false
                        this.$store.state.wrapModal=null;
                    })
                    .catch((err) => {
                        this.loading = false;
                        if(!err.data.errors)
                        {
                            this.errorAlert(err.data.data)

                        }



                        this.errors = err.data.errors;
                        console.log(this.errors);

                    });


            },

        // copy past
        fetchData() {
            api.get("/users")
                .then(resp => {
                    this.users = resp.data.users
                    console.log("the status is ", resp.status);
                    if (resp.status === 200 || resp.status === 204) {
                        this.status = true
                    }
                    else {
                        this.status = false
                    }
                })
                .catch(err => {
                    console.error(err)
                })

            api.get("/entreprise")
                .then(resp => {
                    this.entreprise = resp.data
                    console.log("the entreprise is ", this.entreprise);
                    if (resp.status === 200 || resp.status === 204) {
                        this.status = true
                    }
                    else {
                        this.status = false
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        },
    }
}
</script>

<style lang="scss" scoped></style>