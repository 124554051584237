<template>  
  <div style="margin-top:70px;">
    <button @click="click" class="flex rounded-sm w-auto p-2 bg-gray-200 hover:bg-gray-600">
              <span class="material-icons">keyboard_double_arrow_left</span>
    </button>
  </div>

  <div v-if="status==true">
  
    <div class="block  bg-white p-6  dark:bg-neutral-700 " style="margin-top:30px;width:90%; margin-left:50px; height: 80%;">
       
      <Loading v-show="loading" class="h-full"/>
      <h1 class=" text-3xl flex justify-center mb-3 ">Return Form</h1>
      
      <h1 class=" flex justify-left mt-5 text-3x">Info:</h1> 
          <div class="mb-6"> 
            
              <div class=" mt-5">
                <p class="Py-2 mx-3">Recepteur:<span>{{ form.clientname }}</span></p>
                <p class="Py-2 mx-3">Conditions:<span  v-if="event!=null">{{ event.name }}</span><span v-else>{{ location.conditions }}</span></p>
                <p class="Py-2 mx-3">Date delivré:<span>{{ form.date }}</span></p>

              </div>  
          </div> 
        <div style="border-bottom: 4px solid gray; margin-left: 0px; margin-bottom: 5px;"></div>
        
           <h1 class="flex justify-left mt-6  text-3x">Equipment:</h1> 
        
        <form action="" @submit.prevent="saveInformation" class="" ref="ArticleForm">
        <div class="mt-2 relative overflow-x-auto" >
          <table class="divide-x divide-y  divide-black-600 w-full" id="dataTable">
                <thead class="p-3 bg-black   border sm:rounded-lg">
                   <tr class="text-white">
                      <th>name</th>
                      <th> Sortie</th>
                      <th> Retour</th>
                      <th>Etat</th>
                      <th>commentaire</th>
                   </tr>
                </thead>
              <tbody class="bg-white divide-y divide-gray-300">
          
                  <tr v-for="(item,index) in articles"  :key="item.id" class="t-body">
                     <td class="px-6 py-4">
                       <h6> 
                          {{ item.article.name }}
                       </h6>
                     </td>
                     <td class="px-6 py-4">
                       <h6>
                           {{ item.quantity }}
                       </h6>
                     </td>
                     <td class="px-6 py-4">
                     <input type="text" @keyup="onchange(index)"  placeholder="Quantit retour"  v-model="form.quantity[index]"
                        class="bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"> 
                     </td>
                     <td class="px-6 py-4">
                       <select name="" id="" @change="changestate(index)"  v-model="form.state[index]" class=" border-b focus:border-buja-primary ">
                        <option class="hover:bg-green-300"></option>
                       <option class="hover:bg-green-300">bad</option>
                       <option class="hover:bg-green-300">good</option>
                       <option class="hover:bg-green-300">notgood</option>
                     </select> 
                     </td>
                     <td class="px-6 py-4">
                        <input type="text" @keyup="changecomment(index)"   placeholder="commentaire"  v-model="form.comments[index]"
                           class=" bg-transparent border-b focus:border-buja-primary focus:outline-none focus:shadow-[0px_1px_0_0_#004E71]"> 
                     </td>
                  </tr>
                </tbody>
          </table> 
            <div class="flex gap-8 mt-5">
                <button type="submit" class=" rounded-sm p-3 bg-green-600 hover:bg-green-400">Enregister</button>
                <button type="reset" class=" rounded-sm p-3 bg-red-600 hover:bg-red-400">Empty</button>
            </div>
          </div>  
        </form>
         
        
    </div>
    </div>
    <div v-else>              
                <tableHolder class="w-full"/>
    </div>
 

    
</template>
<script>
import api from '../../../api.js'
import result from '@/mixins/result';
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";


export default {
    props:['id'],
    mixins:[result],
    
    data()
    {
        return{
            form:{
                location_id:"",
                Retour:1,
                article_id:{},
                quantity:{},
                client_id:'',
                state:{},
                events:"",
                comments:"",
                clientname:"",
                date:"",
                inputs:[],
                articles:[],
            },
            location:[],
            articles:[],
            return:[],
            status:null,
            returnarticles:[],
            errors:[]
        }
    },
    watch:{
      articles(val) {
          console.log(val)
          $('#dataTable').DataTable().destroy();
          this.$nextTick(()=> {
            $('#dataTable').DataTable()
          });
        },
},
    mounted()
    {
        //console.log( this.id )
        this.getsortie(this.id)
        this.getreturn(this.id)
        this.getlocation()
    },
   
    components: {
    //'Loading': require('../Global/Loading.vue').default,
    'tableHolder':require('@/components/Global/tableSkeleton.vue').default,
    'wrapModal':require('@/components/Global/wrapModal.vue').default,
  },
   
    methods:{
        onchange(index)
        {
                this.articles[index].quantityR=this.form.quantity[index]            
        },
        changecomment(index)
        {
          this.articles[index].comments=this.form.comments[index]

        },
        changestate(index)
        {
          this.articles[index].state=this.form.state[index]

        }
        ,
        getlocation() {
            let data = { id: this.id }
            api.get("/getlocation", { params: { id: this.id } })
                .then(resp => {
                 this.articles=(resp.data)
            this.articles.forEach(function(item){item.quantityR = '', item.state='', item.comments=''});
            console.log(resp.data)
                })
                .catch(err => {
                    console.error(err)
                })
               
        },
        
        getsortie(id)
        {
        api.get("/location/"+id)
        .then(resp => {
          if(resp.status===200 || resp.status===204){
                this.status=true
            }
            else{
                this.status=false
            }
            this.location = resp.data[0]
            this.$store.state.locationid=this.location
            this.form.location_id=this.location.id
            this.form.clientname= this.location.client.name
            this.form.events=this.location.events
            this.form.client_id=this.location.client.id
            this.form.date=this.location.nb_days.substr(0, 10);
            
        })
        .catch(err => {
            console.error(err)
        })


        },
        click()
        {
          this.$router.go(-1)

        },
        getreturn(id)
        {
        api.get("/return-article/"+id)
        .then(resp => {
            this.returnarticles = resp.data
            let detail=resp.data.returndetail
            console.log(detail)
            console.log(this.returnarticles)
            /*if(resp.status===200 || resp.status===204){
                this.status=true
            }
            else{
                this.status=false
            }*/ 
        })
        .catch(err => {
            console.error(err)
        })
        },
        saveInformation() { 
          //this.status = false;
          let result=[]
          let at=[]
          at=this.articles.filter((item)=>item.quantityR!=='' && item.state!=='' || item.comments!=='')
          at.forEach(element => {
            if((element.quantityR*1) > (element.quantity*1))
            {
              result.push(element)
            }
            else{
              console.log('hi')
            }
          });
          console.log(at)
          console.log(result)
        if(result.length>0)
          {
            alert('invalide quantity')

          }
          else{
          this.form.articles=at  
          
          console.log(at);
          console.log(this.form.articles);

          if (this.form == {}) return;
              api.post(
                "/return-article",
                this.form
              )
                .then((resp) => {
                 
                  this.locations = resp.data;
                  this.successAllert()
                  //this.status = true;
                 
                })
                .catch((err) => {
                  
                  err.data.message.forEach(element => {
                    this.errors=(JSON.stringify(element))
                    
                  });
                  //alert((this.errors))
                  this.errorAlert("invalid quantity , check your input and try again")
                  //this.status = true;
                  console.error(err.response.data.errors);
                  this.errors = err.response.data.errors;
                 
                });
            } 

            

          }

          

      },
        
    }


</script>

<style>

</style>