<template>
    <main>
        <div class="p-5">
            <div v-if="status">
                <div>
                    <h1 class="text-2xl font-bold text-center mt-16">{{ $t('reportMsg') }}</h1>
                    <div class="flex gap-10 mt-10">
                        <span>{{ $t('startDateMsg') }} : <input type="date" name="" id="" v-model="start_date" @change="dateDebut" class="w-40"></span>
                        <span>{{ $t('endDateMsg') }}  :  <input type="date" name="" id="" v-model="end_date" @change="dateFin" class="w-40"></span>
                    </div>
                </div>
                <div class="grid grid-cols-2 mt-5 max-sm:grid-cols-1">

                    <div class="flex  mb-16">
                        <div class="flex justify-between items-center rounded-lg bg-white p-6 shadow-lg  w-[500px]">
                            <div class="">
                                <h5 class=" mb-6 text-xl font-bold leading-tight text-neutral-800 ">
                                    {{ $t('invoiceWithoutVATMsg') }}
                                </h5>
                                <p class="mb-4 text-2xl text-neutral-600   font-bold">
                                    {{ formatNumber(price_nvat) }}
                                </p>
                            </div>
                            <div class=" self-center">
                                <span class="material-icons text-3xl">money</span>
                                <span class="material-icons text-6xl">currency_franc</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex  mb-16">
                        <div class="flex justify-between items-center rounded-lg bg-white p-6 shadow-lg  w-[500px]">
                            <div class="">
                                <h5 class="mb-6 text-xl font-bold leading-tight text-neutral-800 ">
                                    {{ $t('totalVATMsg') }}
                                </h5>
                                <p class="mb-4 text-2xl text-neutral-600  font-bold">
                                    {{ formatNumber(price_vat) }}
                                </p>
                            </div>
                            <div class=" self-center">
                                <span class="material-icons text-3xl">money</span>
                                <span class="material-icons text-6xl">currency_franc</span>
                            </div>
                        </div>
                    </div>

                    <div class="flex  mb-16">
                        <div class="flex justify-between items-center rounded-lg bg-white p-6 shadow-lg  w-[500px]">
                            <div class="">
                                <h5 class="mb-6 text-xl font-bold leading-tight text-neutral-800 ">
                                    {{ $t('invoiceWithVATMsg') }}
                                </h5>
                                <p class="mb-4 text-2xl text-neutral-600  font-bold">
                                    {{ formatNumber(total_price_wvat) }}
                                </p>
                            </div>
                            <div class=" self-center">
                                <span class="material-icons text-3xl">money</span>
                                <span class="material-icons text-6xl">currency_franc</span>
                            </div>
                        </div>
                    </div>

                    <div class="flex  mb-16">
                        <div class="flex justify-between items-center rounded-lg bg-white p-6 shadow-lg  w-[500px]">
                            <div class="">
                                <h5 class="mb-6 text-xl font-bold leading-tight text-neutral-800 ">
                                    {{ $t('cancelledInvoiceMsg') }}
                                </h5>
                                <p class="mb-4 text-2xl text-neutral-600  font-bold">
                                    {{ formatNumber(factureCancelled) }}
                                </p>
                            </div>
                            <div class=" self-center">
                                <span class="material-icons text-3xl">money</span>
                                <span class="material-icons text-6xl">currency_franc</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <tableHolder />
            </div>

        </div>

    </main>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import api from '../../api.js'
import "jquery/dist/jquery.min.js";
import result from '@/mixins/result';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
export default {

    data() {
        return {
            requisitions: [],
            start_date: new Date().toISOString().slice(0, 10),
            end_date: new Date().toISOString().slice(0, 10),
            price_nvat: 0,
            price_vat: 0,
            total_price_wvat: 0,
            factureCancelled: 0,
            status: null
        }
    },
    mixins: [result],
    components: {
        'tableHolder': require('@/components/Global/tableSkeleton.vue').default,
    },
    watch: {
        requisitions(val) {
            console.log(val)
            $('#datatable').DataTable().destroy();
            this.$nextTick(() => {
                $('#datatable').DataTable()
            });
        },
    },
    mounted() {
        var x = new Date();
        x.setDate(x.getDate() + 1);
        this.end_date = x.toISOString().split('T')[0]
        this.fetchData()
    },
    methods: {
        dateDebut() {
            this.start_date = this.start_date
            this.fetchData()
            console.log('date debut :', this.start_date);
        },
        dateFin() {
            this.end_date = this.end_date
            this.fetchData()
            console.log('date fin :', this.end_date);
        },
        fetchData() {
            api.get("/reports/?start_date=" + this.start_date + "&end_date=" + this.end_date)
                .then(resp => {
                    this.price_nvat = resp.data.price_nvat,
                        this.price_vat = resp.data.price_vat,
                        this.total_price_wvat = resp.data.total_price_wvat
                    this.factureCancelled = resp.data.factureCancelled
                    console.log("the status is ", resp.status);
                    if (resp.status === 200 || resp.status === 204) {
                        this.status = true
                    }
                    else {
                        this.status = false
                    }
                })
                .catch(err => {
                    console.error(err)
                })
        },
        formatNumber(number) {

            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'FBU' }).format(number);
        },
    },
    computed: {

    }
}

</script>

<style lang="scss" scoped>
.material-icons {
    font-variation-settings:
        'FILL' 0,
        'wght' 100,
        'GRAD' -25,
        'opsz' 40
}

// animated modal
.wrapModal-enter-active,
.wrapModal-leave-active {
    transition: 0.8s ease all;
    position: relative;
    z-index: 99;
}

.wrapModal-enter-from,
.wrapModal-leave-to {
    transform: translateX(700px);
    position: relative;
    z-index: 99;
}
</style>