<template>
  <div class="p-5">
    <h2 class="text-3xl text-center font-bold mt-12 pb-2 text-neutral-500">{{ event.name }}</h2>
</div>

<div class="grid grid-cols-3 mt-5 max-sm:grid-cols-1 ">

<div class="flex justify-center mb-3">
 <div class="flex justify-between items-center rounded-lg bg-white p-6 shadow-lg  w-[350px] ">
     <div class="">
         <h5 class="mb-2 text-xl font-bold leading-tight text-neutral-800 ">
         Total orders
         </h5>
         <p class="mb-4 text-base text-neutral-600  font-bold">
         {{ result.nborders }} tickets
         </p>
     </div>
     <div class=" self-center">
         <span class="material-icons text-6xl">book_online</span>
     </div>
 </div>
</div>

<div class="flex justify-center mb-3">
 <div class="flex justify-between items-center rounded-lg bg-white p-6 shadow-lg  w-[350px]">
     <div class="">
         <h5 class="mb-2 text-xl font-bold leading-tight text-neutral-800 ">
         Scanned Ticket
         </h5>
         <p class="mb-4 text-base text-neutral-600  font-bold">
            {{ result.scanned }} tickets
         </p>
     </div>
     <div class=" self-center">
         <span class="material-icons text-6xl">book_online</span>
     </div>
 </div>
</div>

<div class="flex justify-center mb-3">
    
 <div class="flex justify-between items-center rounded-lg bg-white p-6 shadow-lg  w-[350px]">
     <div class="">
         <h5 class="mb-2 text-xl font-bold leading-tight text-neutral-800 ">
         Refund Ticket
         </h5>
         <p class="mb-4 text-base text-neutral-600  font-bold">
            {{ orders.pending }} tickets
         </p>
     </div>
     <div class=" self-center">
         <span class="material-icons text-6xl">book_online</span>
     </div>
 </div>
</div>
</div>
<!--<div>
    <h5
      class="mb-2 mx-4 text-xl lg:text-start sm:text-center xs:text-center font-medium text-neutral-800 dark:text-neutral-500">
         Type Ticket
    </h5>
</div>-->

<div class="container bg-white  mx-auto p-3">
    
    <section class="mb-4 text-gray-800 text-center">
        <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-x-3 lg:gap-x-0 items-center ">
            <div class="mb-12 lg:mb-0 relative"  v-for="ticket in ticketprice" :key="ticket.id">
     
                <h5 class="text-lg font-medium text-blue-600 font-bold mb-4">{{ ticket.count }}</h5>
                <h6 class="font-medium text-gray-500">{{ ticket.type_ticket.name }}</h6>
                <hr class="absolute right-0 top-0 w-px bg-gray-200 h-full  lg:block sm:block " />
             </div>
          
          
        </div>
    </section>
</div>
<!-- Container for demo purpose -->
       <!-- table -->
       <div>
    <h5
      class="mb-2 mt-6 mx-4  text-xl lg:text-start sm:text-center xs:text-center font-medium text-neutral-800 dark:text-neutral-500">
         Orders
    </h5>
</div>
       <div v-if="status">
           <div class="p-5 mt-3 relative overflow-x-auto shadow sm:rounded-lg border-b border-gray-200">
            <table class="w-full" id="dataTable">
                    <thead class="bg-black">
                        <tr>
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t ('ticketNumberMsg') }} 
                            </th>
                           
                           
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t ('TickeTypeMsg') }}
                            </th>
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t ('QuantityMsg') }}
                            </th>
                           
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t ('ticketPrice') }}
                            </th>
                            
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                {{ $t ('totalPriceMsg') }}
                            </th>
                            <th scope="col" class="px-6 py-2 text-xs text-white capitalize">
                                status
                            </th>
                             
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-300">
                        <tr v-for="order in orders" :key="order.id" class=" hover:bg-green-200 hover:cursor-pointer text-center whitespace-nowrap hover:text-buja-primary">
                            <th class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{order.ticket_number}}
                                </div>
                            </th>
                           
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{order.type_ticket.name}}
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{order.quantity}}
                                </div>
                            </td>
                           
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{order.montant}}
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{order.total_price}}
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="text-sm text-gray-900">
                                    {{order.status}}
                                </div>
                            </td>
                        
                            

                        </tr>
                    </tbody>
                    
                    
                </table>
           </div>
       </div> 
       <div v-else>              
           <tableHolder/>
       </div>


</template>

<script>
import { mapMutations,mapState} from 'vuex';
import api from '../../../../api.js'
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import result from '../../../mixins/result';
export default {
    data()
    {
        return{
            info:[],
            result:[],
            orders:[],
            status:null,
            event:'',
            ticketprice:[],
            data:{
                id:""
            }
            
        }

    },
    props:['id'],
    components:{
    'tableHolder':require('@/components/Global/tableSkeleton.vue').default,
    },
    
    mounted()
    {
        this.geteventdetail(this.id)
        this.geteventorders()
    },
    watch:{
        orders() {
          $('#dataTable').DataTable().destroy();
          this.$nextTick(()=> {
            $('#dataTable').DataTable()
          });
        }
},

    
    methods:{
        geteventdetail(id)
        {
            api.get("/event/"+id)
        .then(resp => {
            this.info = resp.data
            this.event=this.info.event
            this.ticketprice=this.info.tickprice
            this.ticketprice.forEach((item)=>{item.count=0})
            console.log(this.info)
            console.log(this.event)
            console.log(this.ticketprice)

            if(resp.status===200 || resp.status===204 || resp.status===201 ){
                this.status=true
            }
            else{
                this.status=false
            } 
        })
        .catch(err => {
            console.error(err)
        })
            
        },
        geteventorders()
    {
        this.data.id=this.id
        api.get("/geteventorder/",{params:{id:this.id}})
        .then(resp => {
            this.result = resp.data
            this.orders=this.result.eventorders
            var res=[]
            var rez={};
            this.result.eventorders.forEach(function(item){
              rez[item.type_ticket_id] ? rez[item.type_ticket_id]++ :  rez[item.type_ticket_id] = 1;
            });
            res.push(rez)
            this.ticketprice.forEach(item => {
                res.forEach(element => {
                    if(item.type_ticket.id==Object.keys(element))
                    {
                        item.count=Object.values(element)    
                    }
                    else{
                        item.count=0
                    }    
                    
                });
                    
            });
            console.log(rez)
            console.log(Object.keys(rez))
            console.log(res)
            console.log(this.ticketprice)
            console.log(this.orders);

            
           
        })
        .catch(err => {
            console.error(err)
        })
        

    }
    },
    

}
</script>

<style>

</style>