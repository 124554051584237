<template>
    <!-- <div :class="`${loading ? 'fixed p-[56px] bg-[rgb(20,22,37)] max-w-[700px] h-screen text-white ' : 'buja-form-content'}`" ref="form-content"> -->
      <div :class="`${loading ? 'buja-form-content' : ' buja-form-content'}`" ref="form-content" class="FormCl">
       <!-- <div class="buja-form-content FormCl" ref="form-content"> -->
  
      <Loading v-show="loading" class="h-full"/>
        <h1 class=" text-3xl flex justify-center mb-3">{{ $t('RoleMsg') }}</h1>
        <form action="" @submit.prevent="saveinfo" ref="userform"> 
            <div class=" p-3">
              <label for="" class="text-gray-500">{{ $t('userMsg') }} :</label>
              <select name="" id="" required v-model="form.user_id" class="select-buja">
                <option v-for="user in users"  :key="user.id" :value="user.id" class="">
                  {{ user.name }}
                </option>
              </select>
               <span>{{ errors?.user_id[0] }}</span>
            </div>
            <div class="p-3">
              <label for="" class="text-gray-500">{{ $t('Roles') }} :</label>
                <div class="relative z-0" v-for="role in roleuser" :key="role.id">
                    <!--<input type="text" disabled="disabled" required id="default_standard" v-model="role.name" class="input-buja" placeholder=" " />-->
                    <span>{{ role.name }}</span>
                </div>
                <div>{{ errors?.user_role_name[0] }}</div>
             </div>
            
            <div class="p-3">
              <label for="" class="text-gray-500">{{ $t('NewRoleMsg') }} :</label>
              <select name="" id="" required v-model="form.role_id" class="select-buja">
                <option v-for="role in rolefilter"  :key="role.id" :value="role.id" class="">
                  {{role.name}}
                </option>
              </select>
               <span>{{ errors?.role_id[0] }}</span>
            </div>
            <div class="flex gap-8 mt-5">
                <button type="submit" class="btn-green-add">{{saveEditBtn}}</button>
                <button type="reset" class="btn-red-empty">{{ $t('btnEmpty') }}</button>
              </div>
          </form>
          <button @click="toggleModal" class="btn-gray-close">{{ $t('btnClose') }}</button>
    </div>   
  </template>
  
  <script>
  
  import { mapGetters, mapMutations,mapState } from 'vuex';
  import api from '../../../api.js'
  import result from '../../mixins/result'
  import i18n from '@/plugins/i18n';
  export default{
        mixins: [result],
        
        data(){
          
            return{
            form:{
                role_id:"",
                user_id:"",
                user_role_id:"",
                user_role_name:[]
            },
            loading:null,
            users:[],
            roles:[],
            roleuser:[],
            rolefilter:[],
           
            saveEditBtn:this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
            }
        },
        components:{
            'Loading':require('../Global/Loading.vue').default
        },
        watch:{
          "form.user_id"(val)
          {
            
            let roles=[]
            roles=this.users.filter((item)=>item.id==this.form.user_id)
            let i=(JSON.stringify(roles))
            let l=(JSON.parse(i)[0])
            this.roleuser=l?.roles
            this.rolefilter = this.roles.filter((el) => {
              return this.roleuser.every((f) => {
                return f.id !== el.id 
              });
            });
            console.log(this.roleuser)
            console.log(this.roles)
          }

        },
  
        mounted()
        {
          this.getuser_role()
           if(this.UserId==null){
              this.form={};
              this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Save" : "Enregistrer"
              }else{
                this.form=this.$store.state.AllUsers;
                this.saveEditBtn=this.$store.state.lacalLanguage==="en" ? "Edit" : "Modifier"
              }
              console.log(this.UserId)
        },
        computed:{
        ...mapState(['UserId','AllUsers']),
    },
        methods:{
            
                ...mapMutations(['WRAP_MODAl','TOGGLE_MODAL']),
  
                toggleModal(){
                    this.WRAP_MODAl();  
                    this.$store.state.UserId=null;
                    this.$emit('close')
                },
                SaveData(){
                    this.loading=true;
                },
                getuser_role()
                {
                  api.get("/users")
                  .then(resp => {
                      this.users =resp.data.users
                      this.roles=resp.data.roles 
                      console.log(this.roles)
                  })
                  .catch(err => {
                      console.error(err)
                  })

                },
              
            saveinfo(){
             
            this.loading = true;
            if (this.form["user_id","role_id"] == "") return;
  
            if (this.categoryId == null) {
                api.post(
                    "/addRole",
                    this.form
                )
                    .then((resp) => {
                      this.loading = false;
                      this.successAllert() 
                    })
                    .catch((err) => {
                      this.loading = false;
                      console.log(err.data.errors);
                      this.errors = err.data.errors;
                      this.errorAlert()
                    });
            } else {
                api.patch(
                    "/register/" + this.categoryId,
                    this.form)
                    .then((resp) => {
                        this.successAllert()
                        this.category = resp.data;
                        this.$store.state.wrapModal=null;
                    })
                    .catch((err) => {
                        this.loading = false;
                       // this.errorAlert()
                        console.log(err);
                       // this.errors = err.response.data.errors;
                        this.errorAlert()
                    });
  
            }
  
        }
        },
       
    }
  </script>
  
  <style lang="scss" scoped>
  // .FormCl{
  //   @media (max-width: 1024px) {
  // 			margin: auto;
  // 		}
  // }
  </style>